export function downloadCsv(filename: string, data: string[][]): void {
  const csvContent = `data:text/csv;charset=utf-8,${data.map((e) => e.join(",")).join(" \n")}`;

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename);
  document.body.appendChild(link); // Required for Firefox

  link.click();

  link.remove();
}
