import React, { FC, useState } from "react";

import { Icon } from "components/Icon";

import { TextField } from "./TextField";
import type { Props as TextProps } from "./TextField";

import styles from "./password.module.css";

export const PasswordField: FC<TextProps> = (props) => {
  const [visible, setVisible] = useState(false);
  return (
    <TextField
      {...props}
      // @ts-ignore TODO: remove once we will finish login flow with api
      // WebkitTextSecurity does not work on Safari and IE
      // style={{ WebkitTextSecurity: visible ? "none" : "disc" }}
      suffix={
        <Icon
          className={styles.icon}
          name={visible ? "eye" : "eye-invisible"}
          size={16}
          onBlur={(e) => e.preventDefault()}
          onClick={(e) => {
            e.preventDefault();
            setVisible((s) => !s);
          }}
          onFocus={(e) => e.preventDefault()}
        />
      }
      type={visible ? "text" : "password"}
    />
  );
};
