export const logger = {
  // eslint-disable-next-line
  error: (error: any, more?: any): void => {
    // eslint-disable-next-line no-console
    console.error(error, more);
    // TODO: use sentry or some logger for FE errors
  },

  setUser: (
    _user: { id?: string; email?: string; fullName?: string; name?: string } | null
  ): void => {
    // Setup user credentials for current session
  },
};
