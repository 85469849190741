import { useCustomer, useUser } from "state";

export function useLoggedIn(): boolean {
  const user = useUser();
  const customer = useCustomer();

  if (!user || !customer || !user?.refreshTokenExpiry) {
    return false;
  }

  return new Date(user?.refreshTokenExpiry) > new Date();
}
