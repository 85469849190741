import cn from "classnames";
import { Icon } from "components/Icon";
import React, { FC, forwardRef } from "react";

import styles from "./tag.module.css";

interface Props {
  color?: "primary" | "secondary" | "success" | "warning" | "danger";
  size?: "large" | "medium" | "normal" | "small";
  onClose?: () => void;
  onAdd?: () => void;
  className?: string;
  hasBorder?: boolean;
}

export const Tag: FC<Props> = forwardRef<HTMLSpanElement, Props>(
  ({ color = "secondary", hasBorder = true, className, children, onClose, onAdd, size }, ref) => {
    return (
      <span
        ref={ref}
        className={cn(
          styles.tag,
          onAdd ? styles["tag-add"] : styles[`tag-${color}`],
          {
            [styles[`tag-${size}`]]: size && size !== "normal",
            [styles["tag-bordered"]]: hasBorder,
          },
          className
        )}
      >
        {onAdd && <Icon className="mr-2" data-testid="add" name="plus" size={10} onClick={onAdd} />}
        {children}
        {onClose && (
          <Icon className="ml-2" data-testid="close" name="close" size={10} onClick={onClose} />
        )}
      </span>
    );
  }
);
