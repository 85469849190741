import { accounts } from "pages/Reload/accountsMock";

import { login } from "./data/login";
import { customer } from "./data/customer";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let mock: Record<string, () => any> = {
  "-/account-list": () => accounts,
  "-/v1/auth/token": login,
  "/v1/user": customer,
};

mock = process.env.REACT_APP_MOCK_REQUESTS === "true" ? mock : {};

export function appFetch(url: string, options: RequestInit): Promise<unknown> {
  const match: undefined | keyof typeof mock = mock[url]
    ? url
    : Object.keys(mock).find((k) => url.indexOf(k) > -1) || undefined;

  if (match) {
    return new Promise((res) => {
      res({
        json: (): Promise<unknown> => Promise.resolve(mock[match]()),
        status: 200,
      });
    });
  }

  return fetch(url, options);
}
