import { put, all, call, delay, select } from "redux-saga/effects";
import { stringify, logger, appFetch, getCaptchaToken } from "helpers";
import { updateUser } from "../actions";
import { ApiValueSuccess, ApiValueError } from "../AppState";
import { AppState } from "state";
import { message } from "antd";
import i18n from "helpers/i18next";

/**
 * Api call Request options
 */
export interface RequestOptions extends Omit<RequestInit, "body"> {
  /**
   * This prop is only passed when the connector type is "VE".
   * It acts as a flag that helps us in displaying internal server error toasts just for VE side.
   */
  isVE?: boolean;
  /**
   * Query params which will be serialized into query-string
   */
  query?: { [key: string]: unknown };
  /**
   * Append Captcha verification automatically
   */
  captcha?: boolean;
  /**
   * JSON body - optional, it will be serialized
   */
  body?: Record<string, unknown>; // TODO: enable string, stream, binary etc - RequestInit["body"]
  /**
   * min resolve time
   */
  atleast?: number;
  /**
   * request url
   */
  path: string;
  /**
   * Authorization header
   * - if its not presented in prev options
   * - & if its presented in Local state
   * - & if not set to FALSE, append auth header
   */
  auth?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* fetchApi<V = Record<string, any>>({
  captcha,
  atleast,
  query,
  body,
  path,
  auth = true,
  isVE = false,
  ...options
}: RequestOptions): unknown {
  let res: undefined | Response = undefined;
  let error: Error | undefined = undefined;
  let data = undefined;

  const queryParams = { ...query };

  /**
   * Append captcha token to request if its required
   */
  if (captcha) {
    try {
      const token = getCaptchaToken();
      queryParams["captcha-token"] = token;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Captcha failed", e);
    }
  }

  const params = query && Object.keys(queryParams).length > 0 ? stringify(queryParams) : undefined;
  const apiUrl =
    (process.env.REACT_APP_API_URL || "") +
    path.replace(/(#.*)/, "") +
    (params ? `?${params}` : "");
  const requestBody = options.method !== "GET" && body ? JSON.stringify(body) : undefined;
  let status: number;
  let ok: undefined | boolean;

  try {
    const request = {
      ...options,
      body: requestBody,
      headers: {
        ...(options.headers || {}),
        "Content-Type": "application/json",
        accept: "application/json",
      } as Record<string, string>,
      method: options.method || "GET",
      path: apiUrl,
    };

    // Add Authorization header if its not disabled
    if (auth !== false && request.headers.Authorization === undefined) {
      const token = yield select((s: AppState) => s.user?.accessToken);
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
    }

    // TODO: refresh token flow

    const [result] = yield all([call(appFetch, apiUrl, request), delay(Number(atleast) || 0)]);
    res = result;

    status = res?.status || 400;
    ok = !!(!error && status > 199 && status < 300);

    if (res?.status === 401) {
      yield put(updateUser(undefined));
    }

    // Bcs of 200 could be empty ...
    try {
      data = res && res.status !== 204 ? yield res.json() : undefined;
    } catch (e) {
      data = undefined;
    }

    if (!ok) {
      data &&
        logger.error(data.message, {
          message: "Invalid apiCall",
          request: { options, path },
          response: { status: res?.status },
        });

      if (
        isVE &&
        data?.message?.includes("Load Account is restricted") &&
        res?.status === 400 &&
        apiUrl.includes("v1/user/accounts")
      ) {
        message.error(
          i18n.t("LOAD_ACCOUNT_RESTRICTED", { id: data.id, timestamp: data.timestamp })
        );
        error = undefined;
      } else if (isVE && data && res?.status === 500) {
        /**
         * If the connector type is VE and it's an internal server error then throw the below error toast.
         * After that we should set the error to undefined to prevent any other error toast from coming in such scenarios.
         */
        message.error(i18n.t("INTERNAL_SERVER_ERROR", { id: data.id, timestamp: data.timestamp }));

        error = undefined;
      } else {
        error =
          (data && data.message
            ? Array.isArray(data.message)
              ? data.message.join(", ")
              : data.message
            : data) || "Something went wrong"; // TODO: translate ?? but this is BE workaround so ...
      }
    }
  } catch (e) {
    logger.error(e, { message: "Invalid apiCall data orchestrating" });
    status = 400;
    error = e;
  }

  const response =
    error || (status && status > 399)
      ? ({
          data: data as unknown,
          error,
          isLoading: false,
          ok: false,
          status: status || 500,
        } as ApiValueError)
      : ({ data, isLoading: false, ok: true, status } as ApiValueSuccess<V>);

  if ("error" in response && response.error) {
    if (response.error instanceof Error) {
      response.error.message = beautifyMessage(response.error.message);
    } else if (typeof response.error === "string") {
      // @ts-ignore - just for sure
      response.error = beautifyMessage(response.error);
    }
  }

  return response;
}

function beautifyMessage(msg: string): string {
  return [...(msg || "").split(/(Exception:)|(Description:)/).reverse(), msg][0];
}
