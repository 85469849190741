import * as Yup from "yup";
import { Col, message } from "antd";
import { useTranslation } from "react-i18next";
import React, { FC, useCallback, useEffect, useRef } from "react";

import { apiServices } from "helpers";
import { Row } from "components/Grid/Row";
import { Button } from "components/Button";
import { Field, Form, TextField } from "components/Form";
import { updateCustomerEffect, useLazyApiCall } from "state";

interface Props {
  onSuccess: () => void;
  onCancel: () => void;
}

export const EmailForm: FC<Props> = ({ onCancel, onSuccess }) => {
  const { t } = useTranslation();
  const schema = Yup.object()
    .shape({ email: Yup.string().nullable().required().email().default(null) })
    .defined();

  const { dispatch, isLoading, error, ok, triggered } = useLazyApiCall(
    apiServices.updateProfile(),
    updateCustomerEffect
  );

  const lastTriggered = useRef(triggered || 0);

  type FormData = Yup.InferType<typeof schema>;

  const initialValues = schema.cast();

  const handleSubmit = useCallback(
    (body: FormData) => {
      !isLoading && dispatch({ body }, 1);
    },
    [dispatch, isLoading]
  );

  useEffect(() => {
    error && message.error(error);
  }, [error]);

  useEffect(() => {
    if (ok && triggered && lastTriggered.current !== triggered) {
      message.success(t("YOUR_EMAIL_CHANGED_SUCCESSFULLY"));
      onSuccess();
    }
  }, [error, ok, onSuccess, t, triggered]);

  return (
    <Form<FormData>
      id="changeEmailForm"
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Field component={TextField} label={t("EMAIL")} name="email" />
        </Col>
        <Col md={8} xs={12}>
          <Button block type="outline" onClick={onCancel}>
            {t("CANCEL")}
          </Button>
        </Col>
        <Col md={8} xs={12}>
          <Button block disabled={isLoading} htmlType="submit" type="primary">
            {t("SAVE")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
