import * as Yup from "yup";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useMemo, useRef } from "react";

import { useConfig, useLazyApiCall } from "state";
import { Link } from "components/Link";
import { Button } from "components/Button";
import { Auth as AuthLayout } from "layouts";
import { Text, Title } from "components/Typography";
import { Form, TextField, Field } from "components/Form";
import { apiServices, getHistory, stringify } from "helpers";

export const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const { dispatch, error, ok, isLoading } = useLazyApiCall(apiServices.forgot());
  const emailRef = useRef(""); // remember last submitted email
  const config = useConfig();
  const isVE = config.connectorType === "VE";
  const siteTheme = config.amsSite?.siteTheme;
  const accountId = siteTheme?.labels?.["label.auth.account.id"];

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          customerCode: Yup.string().required().nullable().default(null),
          email: Yup.string().email().required().nullable().default(null),
        })
        .defined(),
    []
  );

  type Data = Yup.InferType<typeof schema>;

  const initialValues = useMemo(() => schema.cast({}), [schema]);

  const handleSubmit = ({ customerCode, email }: Data) => {
    emailRef.current = email || "";
    dispatch({ body: { customerCode, email } }, 1);
  };

  useEffect(() => {
    //V7 displays the same error for all errors returned by BE, VE displays the error returned by BE
    !isVE && error && message.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
    isVE && error && message.error(error);
  }, [isVE, error, t]);

  useEffect(() => {
    ok &&
      getHistory().pushWithTheme(
        `/forgot-password-success?${stringify({ email: emailRef.current })}`
      );
  }, [ok]);

  return (
    <AuthLayout>
      <Form<Data>
        id="loginForm"
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <div className="d-block flex-col">
          <Title className="mb-3 d-block" level={1}>
            {t("PASSWORD_RECOVERY")}
          </Title>
          <Text className="mb-5 d-block">{t("ENTER_EMAIL_FOR_RESET")}</Text>

          <Field
            component={TextField}
            label={accountId ? accountId : t("ACCOUNT_ID")}
            name="customerCode"
            placeholder={t("ACCOUNT_ID")}
          />

          <Field
            component={TextField}
            label={t("EMAIL")}
            name="email"
            placeholder={t("ENTER_YOUR_EMAIL")}
          />

          <Button block className="mt-4" disabled={isLoading} htmlType="submit" type="primary">
            {t("SEND_RESET_LINK")}
          </Button>

          <div className="text-center mt-4">
            <Link to="/:theme/login">{t("GO_BACK")}</Link>
          </div>
        </div>
      </Form>
    </AuthLayout>
  );
};
