import * as Yup from "yup";
import { FormikConsumer } from "formik";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { Col, Divider, message, Row, Switch } from "antd";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Account, Api } from "api";
import { Card } from "components/Card";
import { Icon } from "components/Icon";
import { Button } from "components/Button";
import { Text, Title } from "components/Typography";
import { PaymentResponse } from "containers/Payment";
import { useConfig, useCustomer, useLazyApiCall } from "state";
import { refreshCustomer } from "state/effects/refreshCustomer";
import { CheckboxField, Field, Form, TextField } from "components/Form";
import {
  getHistory,
  priceValidator,
  getCurrencySymbol,
  apiServices,
  withMaxDecimals,
} from "helpers";

import { PaymentCardRow } from "../ManualReload/components/PaymentCardRow";

export const AutoReload: FC = () => {
  const customer = useCustomer();
  const { t } = useTranslation();
  const {
    params: { id: accountId },
  } = useRouteMatch<{ id: string }>();

  const { ok, error, triggered, dispatch, isLoading } = useLazyApiCall(
    apiServices.autoload(accountId),
    refreshCustomer
  );

  const { dispatch: disableDispatch } = useLazyApiCall(
    apiServices.disableAutoload(accountId),
    refreshCustomer
  );

  const accounts = ((customer && customer.accounts) || []) as Account[];
  const account = accounts.find((it) => it.accountId === accountId);
  const config = useConfig();

  const [disabled, setDisabled] = useState(!account?.autoloadDetails?.subscriptionStatus);

  const [payments] = useState<Array<Api["SavedPayment"] & { data?: PaymentResponse }>>(
    customer && customer.savedPayments && customer.savedPayments.length > 0
      ? customer.savedPayments
      : []
  );

  const schema = useMemo(() => {
    const string = disabled ? Yup.string() : Yup.string().required();

    return Yup.object()
      .shape({
        amount: string
          .nullable()
          .test(priceValidator as never)
          .default(null),
        payment: string.nullable().default(payments && payments.length ? payments[0].id : null),
        replace: Yup.boolean().default(false),
        terms: Yup.boolean()
          .when("payment", {
            is: (v) => v && v.startsWith("newPayment"),
            then: Yup.boolean().required().oneOf([true], t("TERMS_CONDITIONS_MUST_BE_ACCEPTED")),
          })

          .default(false),
        thresholdAmount: string
          .nullable()
          .test(priceValidator as never) // TODO: inspect why typings dont work
          .default(null),
      })
      .defined();
  }, [disabled, payments, t]);

  type FormData = Yup.InferType<typeof schema>;

  const initialValues = useMemo(
    () =>
      schema.cast(
        account && account?.autoloadDetails?.loadAmount
          ? {
              amount: account.autoloadDetails.loadAmount,
              thresholdAmount: account.autoloadDetails.thresholdAmount || 0,
            }
          : {}
      ),
    [account, schema]
  );

  const handleSubmit = (data: FormData) => {
    if (disabled) {
      disableDispatch(undefined, 1);
      return;
    }

    const body = {
      loadAmount: Number((data.amount || "").replace(",", ".")),
      savedPaymentId: data.payment || "",
      thresholdAmount: Number((data.thresholdAmount || "").replace(",", ".")),
    } as Api["AutoloadDetailsPut"];

    dispatch({ body }, 1);
  };

  const {
    params: { theme },
  } = useRouteMatch<{ theme: string }>();

  const goBack = useCallback(() => {
    getHistory().push(`/${theme}/reload`);
  }, [theme]);

  const handleToggleDisable = useCallback(() => {
    setDisabled((s) => !s); // TODO: remove once api will work
  }, []);

  const lastTrig = useRef(triggered || 0);

  useEffect(() => {
    if (error && lastTrig.current < (triggered || 0)) {
      message.error(t("ACCOUNT_AUTOLOAD_SUBSCRIPTION_FAILED"));
    }
    lastTrig.current = triggered || 0;
  }, [triggered, error, t]);

  useEffect(() => {
    if (ok) {
      if (disabled) {
        message.success(t("ACCOUNT_AUTOLOAD_SUBSCRIPTION_DEACTIVATED"));
        goBack();
      } else {
        message.success(t("ACCOUNT_AUTOLOAD_SUBSCRIPTION_ACTIVATED"));
        goBack();
      }
    }
  }, [ok, goBack, t, disabled]);

  if (!account) {
    return null; // TODO: add error
  }

  return (
    <>
      <Form<FormData>
        id="passwordForm"
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <div className="d-flex align-items-center mb-4">
          <Button type="text" onClick={goBack}>
            <Icon name="chevron-left" />
          </Button>
          <Title className="mb-0" level={1}>
            {t("AUTOLOAD_NAME", { name: account?.accountName })}
          </Title>
        </div>

        {/* Config form */}
        <Card className="mb-4" padding="large">
          <Row>
            <Col md={12} xs={24}>
              <div className="w-100 d-flex justify-content-between align-items-center">
                <Text color={!disabled ? "success" : "disabled"} size="medium">
                  {/* TODO: use account.autload */}
                  {!disabled ? t("AUTOLOAD_ENABLED") : t("AUTOLOAD_DISABLED")}
                </Text>
                <Switch checked={!disabled} onChange={handleToggleDisable} />
              </div>
            </Col>
          </Row>

          {!disabled && (
            <>
              <Row className="mt-4">
                <Col md={12} xs={24}>
                  <Field
                    component={TextField}
                    label={t("LOAD_AMOUNT")}
                    name="amount"
                    prefix={getCurrencySymbol()}
                    transformValue={(val) => String(withMaxDecimals(val))}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={24}>
                  <Field
                    component={TextField}
                    label={t("WHEN_BALANCE_DROPS_TO")}
                    name="thresholdAmount"
                    prefix={getCurrencySymbol()}
                    transformValue={(val) => String(withMaxDecimals(val))}
                  />
                </Col>
              </Row>
            </>
          )}
        </Card>

        {!disabled && (
          <>
            {/* Payment Options */}
            <Title className="mb-3" level={5}>
              {t("PAYMENT_METHOD")}
            </Title>

            <Card className="mb-3" padding="none">
              {payments.map((it, k) => (
                <React.Fragment key={it.id + k}>
                  <PaymentCardRow
                    id={it.id}
                    name="payment"
                    number={it.creditCardLast4 || ""}
                    type={it.creditCardType || ""}
                  />
                  <Divider className="m-0" />
                </React.Fragment>
              ))}

              {/* <Row>
                  <Col className="d-flex align-items-center py-3 px-4" md={24} xs={24}>
                    <Button type="link" onClick={() => setOpenNewPayment(true)}>
                      {t("USE_NEW_PAYMENT_METHOD")}
                    </Button>
                  </Col>
                </Row> */}
            </Card>

            <Field
              className="mb-0"
              component={CheckboxField}
              label={
                <>
                  {t("I_AGREE_WITH")}{" "}
                  <a href={config.amsSite?.term} rel="noreferrer" target="_blank">
                    {t("TERMS_AND_CONDITIONS")}
                  </a>
                </>
              }
              name="terms"
            />

            {/* T&C confirmation */}
            <FormikConsumer>
              {({ values: { payment } }) =>
                payment && payment.startsWith("newPayment") ? (
                  <>
                    <Field
                      className="mb-0"
                      component={CheckboxField}
                      label={
                        <>
                          {t("I_AGREE_WITH")}{" "}
                          <a href={config.amsSite?.term} rel="noreferrer" target="_blank">
                            {t("TERMS_AND_CONDITIONS")}
                          </a>
                        </>
                      }
                      name="terms"
                    />
                    <Field
                      className="mb-0"
                      component={CheckboxField}
                      label={t("SAVE_NEW_PAYMENT_METHOD_REPLACE_DEFAULT")}
                      name="replace"
                    />
                  </>
                ) : null
              }
            </FormikConsumer>
          </>
        )}

        {/* Actions */}
        <FormikConsumer>
          {({ values }) => {
            const forceEnable = disabled && account?.autoloadDetails?.subscriptionStatus;
            const btnDisabled = forceEnable
              ? false
              : !values.terms ||
                (disabled === !account?.autoloadDetails?.subscriptionStatus &&
                  (!values.amount || !values.thresholdAmount));
            return (
              <>
                <Row className="mt-5" gutter={16}>
                  <Col lg={4} md={8} xs={12}>
                    <Button
                      block
                      disabled={isLoading || btnDisabled}
                      type="secondary"
                      onClick={goBack}
                    >
                      {t("CANCEL")}
                    </Button>
                  </Col>
                  <Col lg={4} md={8} xs={12}>
                    <Button
                      block
                      disabled={isLoading || btnDisabled}
                      htmlType="submit"
                      type="primary"
                    >
                      {t("SAVE")}
                    </Button>
                  </Col>
                </Row>
              </>
            );
          }}
        </FormikConsumer>
      </Form>
    </>
  );
};
