import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "components/Modal";
import { Alert } from "antd";

import { Payment } from "./Payment";

import type { PaymentResponse } from "./Payment";
import { useConfig } from "state";

interface Props {
  onSuccess?: (res: PaymentResponse) => void;
  onRequestClose?: () => void;
  isOpen?: boolean;
}

export const PaymentModal: FC<Props> = ({ isOpen, onSuccess, onRequestClose }) => {
  const { t } = useTranslation();
  const config = useConfig();
  const isVE = config.connectorType === "VE";

  return (
    <Modal
      actions={null}
      isOpen={!!isOpen}
      title={t("NEW_PAYMENT")}
      onRequestClose={onRequestClose}
    >
      <>
        {!isVE && <Alert showIcon message={t("CARD_REPLACEMENT_INFO")} type="info" />}
        <br />
        <Payment onSuccess={onSuccess} />
      </>
    </Modal>
  );
};
