import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import React, { Fragment, FC, useState } from "react";

import { Tag } from "components/Tag";
import { Icon } from "components/Icon";
import { Text } from "components/Typography";
import { Popover } from "components/Popover";
import { Api } from "api";
import { userFullName } from "helpers";

interface Props {
  shared?: Api["Account"]["sharedCustomerList"];
}

export const SharedTag: FC<Props> = ({ shared }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  if (!shared || shared.length === 0) {
    return null;
  }

  return (
    <Popover
      content={shared.map((i, k, a) => (
        <Fragment key={k}>
          <div className="d-flex align-items-center p-4">
            <Icon className="mr-4" name="user" size={24} />
            <Text size="medium">{userFullName(i)}</Text>
          </div>
          {k < a.length && <Divider className="m-0" />}
        </Fragment>
      ))}
      placement="bottomLeft"
      title={
        <Text strong className="d-block py-2 px-2">
          {t("SHARED_WITH")}
        </Text>
      }
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
    >
      <a
        href="#shared-badge"
        onClick={(e) => {
          e.preventDefault();
          setVisible(true);
        }}
      >
        <Tag color="primary" hasBorder={visible} size="medium">
          <Icon className="mr-1" name="user" /> {shared.length}
        </Tag>
      </a>
    </Popover>
  );
};
