import cn from "classnames";
import React, { FC } from "react";
import { Typography } from "antd";
import { TitleProps } from "antd/lib/typography/Title";

const AntTitle = Typography.Title;

interface Props extends TitleProps {
  className?: string;
}

export const Title: FC<Props> = ({ children, className, ...props }) => {
  return (
    <AntTitle {...props} className={cn(className, {})}>
      {children}
    </AntTitle>
  );
};
