import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "components/Icon";
import { Button } from "components/Button";
import { getHistory } from "helpers/history";
import { Auth as AuthLayout } from "layouts";
import { Text, Title } from "components/Typography";
import { RenderDesktop, RenderMobile } from "../../components/RenderMatchMedia";
import { Select } from "antd";
import styles from "./common.module.css";
import i18n from "i18next";
import icon from "../../assets/images/language.svg";
import { parseBrowserLanguage } from "../../App";
import { useLazyApiCall } from "../../state";
import { apiServices } from "../../helpers";

export const GuestSuccess: FC = () => {
  const { t } = useTranslation();
  const [currentLang, setCurrentLang] = useState(parseBrowserLanguage(i18n.language));
  const [countdown, setCountdown] = React.useState(60);

  const guest = !!localStorage.getItem("guest")
    ? JSON.parse(localStorage.getItem("guest") || "").profile
    : undefined;

  const { dispatch } = useLazyApiCall(apiServices.emailResend(guest?.id));

  React.useEffect(() => {
    countdown > 0 && setTimeout(() => setCountdown(countdown - 1), 1000);
  }, [countdown]);

  return (
    <>
      <RenderDesktop>
        <Select
          className={styles.languageSelectorDropdown}
          defaultValue={currentLang}
          size="large"
          value={currentLang}
          onSelect={(val) => {
            setCurrentLang(val);
            i18n.changeLanguage(val);
          }}
        >
          <Select.Option value={"en"}>
            <div className={styles.languageOption}>
              <span>{i18n.t("ENGLISH")}</span>
              <img alt="" className={styles.langIcon} src={icon} />
            </div>
          </Select.Option>
          <Select.Option value={"fr"}>
            <div className={styles.languageOption}>
              <span>{i18n.t("FRENCH")}</span>
              <img alt="" className={styles.langIcon} src={icon} />
            </div>
          </Select.Option>
        </Select>
      </RenderDesktop>

      <RenderMobile>
        <div className={styles.languageSelectContainer}>
          <div className={styles.currentLanguage}>
            <img alt="" className={styles.langIcon} src={icon} />
            <span>{i18n.language}</span>
          </div>

          <Button
            className={"changeLanguage"}
            type={"link"}
            onClick={() => {
              i18n.changeLanguage(parseBrowserLanguage(i18n.language) === "fr" ? "en" : "fr");
            }}
          >
            {t("CHANGE_LANGUAGE_TO", {
              language: t(parseBrowserLanguage(i18n.language) === "fr" ? "ENGLISH" : "FRENCH"),
            })}
          </Button>
        </div>
      </RenderMobile>

      <AuthLayout noHelpText>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-center">
            <Text className={"mr-2 mt-1"} color="success">
              <Icon name="check-circle" size={32} />
            </Text>
            <Title level={1}>{t("VERIFY_EMAIL_ADDRESS")}</Title>
          </div>

          <div className={`${styles.emailText} mb-5`}>
            <div>{t("EMAIL_VERIFICATION_HEADER")}</div>
            <div className={"d-flex align-items-center"}>
              <Text underline className={"mr-4"}>
                {guest?.email}
              </Text>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div aria-hidden="true" onClick={() => getHistory().pushWithTheme("/guest-sign-up")}>
                <Text strong underline className={styles.editButton} color={"primary"}>
                  {t("EDIT")}
                </Text>
              </div>
            </div>
            <span>{t("EMAIL_VERIFICATION_TEXT")}</span>
          </div>

          <div className={`${styles.emailText} mt-4`}>{t("DIDNT_GET_EMAIL")}</div>

          <Button
            className="w-100"
            disabled={countdown > 0}
            type="primary"
            onClick={() => {
              setCountdown(60);
              dispatch();
            }}
          >
            {countdown <= 0 ? t("RESEND_CONFIRMATION") : t("RESEND_IN", { time: countdown })}
          </Button>
        </div>
      </AuthLayout>
    </>
  );
};
