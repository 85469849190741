import { getLocale } from "./getLocale";

/**
 * Format date time
 * @param {Date} value
 */
export const formatDate = (
  value: Date | string,
  style: "createdAt" | "datetime" | "longDate" | "filename" | "csvExportDate" = "datetime",
  utc?: boolean
): string => {
  try {
    // workaround for invalid date format returned from API
    const v = value && typeof value === "string" ? value.replace(/\.[[0-9]{1,2}]$/, "Z") : value;
    const val = new Date(typeof v === "string" && utc ? Date.parse(v) : v);

    if (style === "filename") {
      const [y, m, d] = val.toISOString().substr(0, 10).split("-");
      return [m, d, y].join("");
    }

    if (style === "createdAt") {
      const date = new Intl.DateTimeFormat(getLocale(), {
        day: "numeric",
        month: "long",
        year: val.getFullYear() !== new Date().getFullYear() ? "numeric" : undefined,
      });
      const time = new Intl.DateTimeFormat(getLocale(), { hour: "numeric", minute: "numeric" });

      return `${date.format(val)} at ${time.format(val)}`;
    }

    if (style === "csvExportDate") {
      const parts = Intl.DateTimeFormat(getLocale(), {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).formatToParts(val);

      return [
        parts.find((i) => i.type === "day")?.value,
        parts.find((i) => i.type === "month")?.value.replace(".", ""),
        parts.find((i) => i.type === "year")?.value,
      ].join("-");
    }

    const options = {
      datetime: {
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        month: "numeric",
        year: "numeric",
      },
      longDate: { day: "numeric", month: "short", year: "numeric" },
    }[style];

    const format = new Intl.DateTimeFormat(getLocale(), options);

    return format.format(val);
  } catch (e) {
    return "Invalid Date";
  }
};
