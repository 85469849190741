// core
import React, { FC, useEffect } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { authResult } from "../../state/effects";
import { apiServices } from "../../helpers";
import { message } from "antd";
import { callApi, updateUser } from "../../state/actions";
import { useDispatch } from "../../state";

export const SsoRedirectPage: FC = () => {
  const getUrlParameter = (name: string) => {
    return getCurrentUrlParameter(window.location.search, name);
  };

  const getCurrentUrlParameter = (url: string, name: string) => {
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);

    const results = regex.exec(url);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const {
    params: { theme },
  } = useRouteMatch<{ theme: string }>();

  const dispatch = useDispatch();

  const refreshTokenStr = getUrlParameter("refresh_token");
  const error = getUrlParameter("error");

  const d = useDispatch();
  useEffect(() => {
    d(updateUser());
  });

  if (refreshTokenStr) {
    dispatch(callApi(apiServices.refreshToken(refreshTokenStr), authResult, -1));

    return <Redirect to={`/${theme}/wallet-dashboard`} />;
  } else {
    message.error(error);
    return <Redirect to={`${theme}/login`} />;
  }
};
