import React, { FC, ReactNode } from "react";
import { Form as AntForm, Checkbox } from "antd";

import "./checkbox.css";

const Item = AntForm.Item;

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | null;
  onBlur?: () => void;
  helperText?: string;
  disabled?: boolean;
  className?: string;
  label?: ReactNode;
  error?: boolean;
  name: string;
}

export const CheckboxField: FC<Props> = ({
  className,
  helperText,
  onChange,
  disabled,
  error,
  value,
  label,
  onBlur,
  name,
}) => {
  return (
    <Item
      className={className}
      help={helperText}
      labelCol={{ span: 24 }}
      validateStatus={error ? "error" : undefined}
    >
      <Checkbox
        checked={!!value}
        disabled={!!disabled}
        name={name}
        onChange={(e) => {
          // @ts-ignore TODO...
          onChange(e);
          // blur after state change
          setTimeout(() => {
            onBlur && onBlur();
          }, 100);
        }}
      >
        {label}
      </Checkbox>
    </Item>
  );
};
