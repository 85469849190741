import { Col } from "antd";
import React, { FC } from "react";

import { Row } from "components/Grid";
import { Text } from "components/Typography";
import { RadioField } from "components/Form";
import { CreditCardIcon } from "components/Icon";
import { maskCardNumber } from "helpers/maskCardNumber";
import { useField } from "formik";

export const PaymentCardRow: FC<{
  number: string;
  type: string;
  id: string;
  name: string;
}> = ({ type, id, number, name }) => {
  const [{ value, onChange }] = useField(name);

  return (
    <Row>
      <Col className="d-flex align-items-center justify-content-between p-4" md={12} xs={24}>
        <div className="px-3">
          <CreditCardIcon type={type} />
          <Text className="ml-2" size="medium">
            {maskCardNumber(number)}
          </Text>
        </div>
        <RadioField name={name} option={id} value={value} onChange={onChange} />
      </Col>
    </Row>
  );
};
