import { Api } from "api";
import deepmerge from "deepmerge";
import { RouteComponentProps } from "react-router-dom";
import React, { FC, ReactElement, useEffect } from "react";

import { apiServices } from "helpers";
import { callApi } from "state/actions";
import { defaultConfig } from "assets/webConfig";
import { updateSiteConfig } from "state/effects";

import { useDispatch, useSelector } from "..";

export interface Config {
  styles: () => Promise<typeof import("*.css")>;
  name: string;
  siteName: string;
  termsLink: string;
  assets?: {
    loginFooterImg?: string;
    loginLogoImg?: string;
    loginVideoLink?: string;
    loginWaiver?: string;
    navLogo?: string;
  };
  siteConfig?: Api["SiteWeb"];
}

const styles = {
  base: () =>
    import(
      /* webpackChunkName: "[default-theme]" */
      "../../themes/default.css"
    ),
  google: () =>
    import(
      /* webpackChunkName: "[google-theme]"  */
      "../../themes/google.css"
    ),
};

type Brand = keyof typeof defaultConfig;

// try to parse initial url
const initialUrlBrand = ((window.location.pathname || "")
  .replace(/^\//, "")
  .split("/")[0]
  .trim()
  .toLowerCase() ||
  process.env.REACT_APP_DEFAULT_SITE_NAME ||
  "base") as Brand;

// remember initial theme
let activeConfig: undefined | Api["SiteWeb"] = defaultConfig[normalizeTheme(initialUrlBrand)];
activeConfig = { ...activeConfig, name: initialUrlBrand };

function normalizeTheme(val: undefined | null | string): Brand {
  return val && Object.keys(defaultConfig).includes(val || "") ? (val as Brand) : "base";
}

/**
 * Return app config based on theme
 */
export function useConfig(): Api["SiteWeb"] {
  return useSelector((s) => s.config);
}

export const WatchConfig: FC = () => {
  const config = useSelector((s) => s.config);

  useEffect(() => {
    activeConfig = config;
  });

  return null;
};

export function WatchTheme({
  match: {
    params: { theme },
  },
}: RouteComponentProps<{ theme: string }>): ReactElement {
  const conf = useConfig();
  const dispatch = useDispatch();
  const currentName = conf.name;

  useEffect(() => {
    if (currentName !== theme) {
      dispatch(callApi(apiServices.site(theme), updateSiteConfig, -1));
    }
  }, [currentName, dispatch, theme]);

  return <></>;
}

/**
 * Return specific styles for site-name
 */
export function getThemeStyles(theme: string): undefined | (() => Promise<typeof import("*.css")>) {
  if (Object.keys(styles).includes(theme)) {
    return styles[theme as keyof typeof styles];
  }
}

/**
 * Get app config outside JSX
 */
export function getConfig(): Api["SiteWeb"] {
  const config = activeConfig || defaultConfig[initialUrlBrand];
  config.name = initialUrlBrand;

  return config;
}

export function mergeConfig(
  a: Partial<Api["SiteWeb"]>,
  b: Partial<Api["SiteWeb"]>
): Api["SiteWeb"] {
  return deepmerge(a, b);
}
