import React, { FC, memo, useState, useEffect, ComponentType } from "react";

import { useSelector } from "..";

interface Props {
  /**
   * Content displayed until App is ready
   */
  initializer?: ComponentType<never>;
  /**
   * Minimum time to display Initial page
   */
  minDelay?: number;

  /**
   *
   */
  ignoreInit?: boolean;
}

/**
 * App wrapper for rendering Initial screen while app is getting ready
 */
export const AppInitializer: FC<Props> = memo(
  ({ children, ignoreInit, initializer: Initializer, minDelay = 500 }) => {
    const initialized = useSelector((s) => s.initialized);
    const [delay, setDelay] = useState(!initialized);

    useEffect(() => {
      // set timeout - minimum app initialization = if we want to display animation = prevent "blink"
      const t = minDelay ? setTimeout(() => setDelay(false), minDelay) : undefined;

      // cleanup
      return () => {
        t && clearTimeout(t);
      };
    }, [setDelay, minDelay]);

    if (ignoreInit || (!delay && initialized)) {
      return <>{children}</>;
    }

    // @ts-ignore TODO: inspect why we cant type Component
    return Initializer ? <Initializer /> : <>loading...</>;
  }
);
