import { TableProps } from "antd/lib/table";
import React, { createRef, FC } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import { Api } from "api";
import { Modal } from "components/Modal";
import { Table } from "components/Table";
import { Button } from "components/Button";
import { formatDate } from "helpers/formatDate";
import { Text, Title } from "components/Typography";

import logo from "assets/images/logo_light_bg.svg";

interface Props {
  data: Api["TransSummary"][];
  onRequestClose?: () => void;
  clientFullName?: string;
  isOpen: boolean;
  cols: TableProps<Api["TransSummary"]>["columns"];
}

export const PrintModal: FC<Props> = ({ cols, clientFullName, data, isOpen, onRequestClose }) => {
  const printContainerRef = createRef<HTMLDivElement>();

  const handlePrint = useReactToPrint({
    content: () => printContainerRef.current,
    onAfterPrint: onRequestClose,
  });
  const { t } = useTranslation();

  return (
    <Modal
      maxHeight
      actions={[
        <Button block key="print" type="secondary" onClick={handlePrint}>
          {t("PRINT")}
        </Button>,
      ]}
      isOpen={isOpen}
      size="large"
      title={t("PRINT_PREVIEW")}
      onRequestClose={onRequestClose}
    >
      <div ref={printContainerRef} className="print-transactions">
        <div className="p-4 d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <img alt="volante brand" src={logo} />
            <Title className="d-inline m-0 ml-3" level={4}>
              {t("VOLANTÉ")}
            </Title>
          </div>
          <Text>
            {formatDate(new Date())} {t("BY_NAME", { name: clientFullName })}
          </Text>
        </div>
        <Table
          // @ts-ignore TODO: make this antd typings work
          columns={cols}
          dataSource={data}
          pagination={false}
        />
      </div>
    </Modal>
  );
};
