import React, { ReactElement, ReactNode } from "react";
import type { FormikConfig } from "formik";
import { Formik, Form as FormikForm } from "formik";

interface Props<Data> extends FormikConfig<Data> {
  children: ReactNode;
  id: string;
}

export function Form<D>({ id, children, ...props }: Props<D>): ReactElement {
  return (
    <Formik<D> {...props}>
      <FormikForm id={id}>{children}</FormikForm>
    </Formik>
  );
}
