import cn from "classnames";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";

import { AppState } from "state";
import { Icon } from "components/Icon";
import { Button } from "components/Button";
import { Avatar } from "components/Avatar";
import { Text } from "components/Typography";
import { Popover } from "components/Popover";
import { userFullName, getHistory } from "helpers";

import "./userDropdown.css";

interface Props {
  user: AppState["customer"];
}

export const UserDropdown: FC<Props> = ({ user }) => {
  const [isVisible, setVisible] = useState(false);
  const { key } = useLocation();
  const { t } = useTranslation();

  const fullName = userFullName(user);
  const email = user?.email;

  // close on route change
  useEffect(() => {
    setVisible(false);
  }, [key]);

  return (
    <Popover
      fixed
      noArrow
      content={[
        <a
          className="d-flex p-4 flex-row align-items-center"
          href="#profile"
          key="my-profile"
          onClick={(e) => {
            e.preventDefault();
            getHistory().pushWithTheme("/profile"); // TODO: use link helper
          }}
        >
          <Icon className="ml-3 mr-3" name="user" size={24} />
          <Text size="medium">{t("MY_PROFILE")}</Text>
        </a>,
        <a
          className="d-block p-4 d-flex flex-row align-items-center"
          href="#1"
          key="logout"
          onClick={(e) => {
            e.preventDefault();
            getHistory().pushWithTheme("/logout"); // TODO: use link helper
          }}
        >
          <Icon className="ml-3 mr-3" name="logout" size={24} />
          <Text size="medium">{t("LOGOUT")}</Text>
        </a>,
      ]}
      placement="bottomRight"
      size="large"
      title={
        <div className="d-flex flex-row align-items-center p-4">
          <Avatar className="mr-2" icon={fullName} />
          <div className="d-flex flex-grow-1 flex-column">
            <Text strong size="medium">
              {fullName}
            </Text>
            <Text color="disabled">{email}</Text>
          </div>
        </div>
      }
      trigger="click"
      visible={isVisible}
      onVisibleChange={setVisible}
    >
      <Button
        className="d-inline-flex align-items-center pr-0"
        type="text"
        onClick={() => setVisible((s) => !s)}
      >
        <Avatar className="mr-3" icon={fullName} size="small" />
        <Text strong size="medium">
          {fullName}
        </Text>
        <span className={cn("ml-2 toggle-icon", { close: !isVisible, open: isVisible })}>
          <Icon name="chevron-down" />
        </span>
      </Button>
    </Popover>
  );
};
