import cn from "classnames";
import { Modal as AntModal } from "antd";
import React, { FC, ReactNode } from "react";

import styles from "./modal.module.css";
import "./modal.css";

interface Props {
  actions?: ReactNode[] | null;
  maxHeight?: boolean;
  padding?: "none";
  isOpen: boolean;
  title?: string;
  size?: string;
  waiver?: boolean;
  onRequestClose?: () => void;
}

export const Modal: FC<Props> = ({
  onRequestClose,
  maxHeight,
  children,
  actions,
  waiver,
  isOpen,
  title,
  size,
}) => {
  return (
    <AntModal
      centered
      className={cn(styles.modal, {
        "max-height": maxHeight,
        waiver: waiver,
        [styles.large]: size === "large",
        [styles.normal]: size === "normal",
      })}
      footer={actions}
      title={title || ""}
      visible={isOpen}
      onCancel={onRequestClose}
    >
      {children}
    </AntModal>
  );
};
