import React, { FC, HTMLAttributes } from "react";
import {
  CloseOutlined,
  TagOutlined,
  TeamOutlined,
  SyncOutlined,
  PlusOutlined,
  CheckOutlined,
  RightOutlined,
  LeftOutlined,
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CheckCircleFilled,
  MenuOutlined,
  WalletOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const iconMap = {
  check: CheckOutlined,
  "check-circle": CheckCircleFilled,
  "chevron-down": DownOutlined,
  "chevron-left": LeftOutlined,
  "chevron-right": RightOutlined,
  close: CloseOutlined,
  eye: EyeOutlined,
  "eye-invisible": EyeInvisibleOutlined,
  info: InfoCircleOutlined,
  logout: LogoutOutlined,
  menu: MenuOutlined,
  plus: PlusOutlined,
  sync: SyncOutlined,
  tag: TagOutlined,
  team: TeamOutlined,
  user: UserOutlined,
  wallet: WalletOutlined,
};

type IconName = keyof typeof iconMap;

interface Props extends HTMLAttributes<HTMLSpanElement> {
  name: IconName;
  className?: string;
  size?: string | number;
}

export const Icon: FC<Props> = ({ name, size, className, ...props }) => {
  const IconComponent = iconMap[name];
  return <IconComponent className={className} style={{ fontSize: size }} {...props} />;
};
