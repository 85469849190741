import { logger } from "helpers/logger";

import { Action } from ".";
import { initialState, AppState } from "./AppState";

export function reducer(state: AppState | undefined, action: Action): AppState {
  if (!state) {
    return initialState;
  }
  switch (action.type) {
    case "START_API_CALL":
      return {
        ...state,
        api: {
          ...state.api,
          [action.path]: action.keepOld
            ? { ...(state.api[action.path] || {}), isLoading: true }
            : { isLoading: true },
        },
      };

    case "RESOLVE_API_CALL":
      return {
        ...state,
        api: { ...state.api, [action.path]: action.response },
      };

    case "UPDATE_USER":
      const { user, customer } = action;
      logger.setUser(user ? { id: user.profile?.id, name: user.profile?.name } : null);
      return {
        ...state,
        customer,
        user,
      };

    case "CLEAR_API_CALL":
      return {
        ...state,
        api: { ...state.api, [action.path]: undefined },
      };

    case "REFRESH_TOKEN_START":
      return {
        ...state,
        refreshInProgress: true,
      };

    case "REFRESH_TOKEN_DONE":
      return {
        ...state,
        refreshInProgress: undefined,
      };

    case "REMEMBER_LOGIN":
      return { ...state, rememberMe: action.value };

    case "INITIALIZED":
      return { ...state, config: action.config || state.config, initialized: true };

    case "UPDATE_CUSTOMER":
      return { ...state, customer: action.customer };

    default:
      return state;
  }
}
