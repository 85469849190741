import { Table as AntdTable } from "antd";
import { useTranslation } from "react-i18next";
import type { TableProps } from "antd/es/table";
import React, { ReactElement, useCallback } from "react";

import { Button } from "components/Button";

import styles from "./table.module.css";
import "./table.css";

// TODO: enable generic table type
interface RecordType {
  [key: string]: unknown;
  id: string | number;
}

interface Props extends TableProps<RecordType> {
  onLoadMore?: () => void;
}

export function Table<R extends RecordType>({
  footer,
  onLoadMore,
  onRow,
  ...props
}: Props): ReactElement {
  const { t } = useTranslation();

  const renderFooter = useCallback(
    (data: R[]) => {
      return (
        <>
          {footer && footer(data)}
          {onLoadMore && (
            // TODO: use our button because of specific varaints
            <Button
              className="mx-auto d-block"
              key="load-more-button"
              type="outline"
              onClick={onLoadMore}
            >
              {t("LOAD_MORE")}
            </Button>
          )}
        </>
      );
    },
    [onLoadMore, footer, t]
  );

  const wrappedOnRow = onRow
    ? (row: RecordType, index: number) => {
        const val = onRow(row, index);
        if (val.onClick || val.onDoubleClick) {
          val.className = ((val.className || " ") + styles.rowAction).trim();
        }
        return val;
      }
    : undefined;

  return (
    <AntdTable
      className="custom-antd-table"
      // @ts-ignore TODO: fix ...
      onRow={wrappedOnRow}
      {...props}
      footer={renderFooter}
    />
  );
}
