import * as Yup from "yup";
import { message, Select } from "antd";
//import { FormikConsumer } from "formik";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useMemo } from "react";

import { apiServices } from "helpers";
import { Link } from "components/Link";
import { Button } from "components/Button";
import { Auth as AuthLayout } from "layouts";
import { Title } from "components/Typography";
import { rememberLogin } from "state/actions";
import { PasswordField } from "components/Form/fields/PasswordField";
import { Form, TextField, Field } from "components/Form";
import { useLazyApiCall, authResult, useSelector, useDispatch, useConfig } from "state";
import { Loader } from "../../components/Loader";
import i18n from "i18next";
import styles from "./common.module.css";
import icon from "../../assets/images/language.svg";
import { RenderDesktop, RenderMobile } from "../../components/RenderMatchMedia";
import { parseBrowserLanguage } from "App";
import Paragraph from "antd/es/typography/Paragraph";

export const Login: FC = () => {
  const { t } = useTranslation();
  const rememberMe = !!useSelector((s) => s.rememberMe);
  const dispatchState = useDispatch();

  const { dispatch, isLoading, error, status } = useLazyApiCall(
    apiServices.login("", ""),
    authResult
  );

  const config = useConfig();
  const isVE = config.connectorType === "VE";

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          customerCode: Yup.string().required().nullable().default(null),
          password: Yup.string().required().nullable().default(null),
          rememberMe: Yup.boolean().default(false),
        })
        .defined(),
    []
  );

  const initialValues = useMemo(() => schema.cast({ rememberMe }), [rememberMe, schema]);

  type FormData = Yup.InferType<typeof schema>;

  const handleSubmit = ({ password, customerCode, rememberMe }: FormData) => {
    dispatchState(rememberLogin(!!rememberMe));

    const body: { customerCode?: string; email?: string; password: string } = {
      password: password || "",
    };
    if (customerCode && customerCode.indexOf("@") > -1) {
      body.email = customerCode;
    } else {
      body.customerCode = customerCode || "";
    }
    dispatch({ body }, 1);
  };

  useEffect(() => {
    if (error) {
      if (
        isVE &&
        status === 400 &&
        !((error as unknown) as string).includes("Your Account is not enabled for My Volante,")
      ) {
        // If it's VE and the status code is 400 while logging in then display a generic error toast.
        message.error(t("INVALID_CREDENTIALS"));
      } else {
        message.error(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const initialized = useSelector((s) => s.initialized);
  const siteTheme = config.amsSite?.siteTheme;
  const accountIdLabel = siteTheme?.labels?.["label.auth.account.id"];
  const loginLabel = siteTheme?.labels?.["label.auth.login"];
  const loginButtonText = siteTheme?.labels?.["button.auth.login"];
  const signupButtonText = siteTheme?.labels?.["button.auth.signup"];
  const employeeSignupButtonLabel = siteTheme?.labels?.["label.employee.signup.button.subject"];
  const guestSignupButtonLabel = siteTheme?.labels?.["label.guest.signup.button.subject"];

  const enableGuestSignUp = config.amsSite?.enableGuestSignup;

  return initialized ? (
    <>
      <RenderDesktop>
        <Select
          className={styles.languageSelectorDropdown}
          defaultValue={parseBrowserLanguage(i18n.language)}
          size="large"
          value={parseBrowserLanguage(i18n.language)}
          onSelect={(val) => i18n.changeLanguage(val)}
        >
          <Select.Option value={"en"}>
            <div className={styles.languageOption}>
              <span>{i18n.t("ENGLISH")}</span>
              <img alt="" className={styles.langIcon} src={icon} />
            </div>
          </Select.Option>
          <Select.Option value={"fr"}>
            <div className={styles.languageOption}>
              <span>{i18n.t("FRENCH")}</span>
              <img alt="" className={styles.langIcon} src={icon} />
            </div>
          </Select.Option>
        </Select>
      </RenderDesktop>

      <RenderMobile>
        <div className={styles.languageSelectContainer}>
          <div className={styles.currentLanguage}>
            <img alt="" className={styles.langIcon} src={icon} />
            <span>{i18n.language}</span>
          </div>

          <Button
            className={"changeLanguage"}
            type={"link"}
            onClick={() =>
              i18n.changeLanguage(parseBrowserLanguage(i18n.language) === "fr" ? "en" : "fr")
            }
          >
            {t("CHANGE_LANGUAGE_TO", {
              language: t(parseBrowserLanguage(i18n.language) === "fr" ? "ENGLISH" : "FRENCH"),
            })}
          </Button>
        </div>
      </RenderMobile>

      <Loader size={"large"} spinning={isLoading}>
        <AuthLayout>
          <Form
            id="loginForm"
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            <div className="d-block flex-col">
              <Title className="mb-5" level={1}>
                {loginLabel ? loginLabel : t("LOGIN")}
              </Title>
              <Field
                component={TextField}
                label={
                  accountIdLabel
                    ? accountIdLabel
                    : enableGuestSignUp
                    ? t("ACCOUNT_ID")
                    : t("ACCOUNT_ID_EMAIL")
                }
                name="customerCode"
                placeholder={t("ACCOUNT_ID_EMAIL")}
              />

              <Field
                autoComplete="off"
                component={PasswordField}
                label={t("PASSWORD")}
                name="password"
                placeholder={t("PASSWORD")}
                type="password"
              />

              <div className="d-flex flex-row mb-4 align-items-center justify-content-end">
                {/* #TODO Uncomment out after "remember me" functionality will be implemented */}
                {/* <Field
              className="mb-0"
              component={CheckboxField}
              label={t("Remember me")}
              name="rememberMe"
            /> */}
                <Link to="/:theme/forgot-password">{t("FORGOT_PASSWORD")}</Link>
              </div>

              <Button block disabled={isLoading} htmlType="submit" type="primary">
                {loginButtonText ? loginButtonText : t("LOG_IN")}
              </Button>

              <div className="text-center mt-5 mb-3">{`${t("DONT_HAVE_ACCOUNT")} `}</div>
              {enableGuestSignUp ? (
                <div className="d-flex align-items-center justify-content-between">
                  <Button className={styles.signupButton} disabled={isLoading} type="primary">
                    <Link to="/:theme/sign-up">
                      {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
                      <Paragraph ellipsis={{ rows: 1 }} style={{ color: "white" }}>
                        {employeeSignupButtonLabel
                          ? employeeSignupButtonLabel
                          : t("EMPLOYEE_SIGNUP")}
                      </Paragraph>
                    </Link>
                  </Button>

                  <Button className={styles.signupButton} disabled={isLoading} type="ghost">
                    <Link to="/:theme/guest-sign-up">
                      <Paragraph ellipsis={{ rows: 1 }}>
                        {guestSignupButtonLabel ? guestSignupButtonLabel : t("GUEST_SIGNUP")}
                      </Paragraph>
                    </Link>
                  </Button>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <Button className={styles.signupButton} disabled={isLoading} type="primary">
                    <Link to="/:theme/sign-up">
                      <Paragraph ellipsis={{ rows: 1 }}>
                        {signupButtonText ? signupButtonText : t("EMPLOYEE_SIGNUP")}{" "}
                      </Paragraph>
                    </Link>
                  </Button>
                </div>
              )}
            </div>

            {}
          </Form>
        </AuthLayout>
      </Loader>
    </>
  ) : (
    <></>
  );
};
