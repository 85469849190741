import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Divider, message, Switch } from "antd";

import { apiServices } from "helpers";
import { Row } from "components/Grid";
import { Card } from "components/Card";
import { Text } from "components/Typography";
import { useConfig, useCustomer, useLazyApiCall } from "state";
import { updateCustomersNotificationsEffect } from "state/effects/updateCustomersNotificationsEffect";

export const Notifications: FC = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const customer = useCustomer()!;
  const config = useConfig();
  const isVE = config.connectorType === "VE";
  // TODO: use primary account id once api supports it
  let accId =
    customer.accounts &&
    (isVE
      ? customer.accounts[0].accountId
      : customer.accounts.find((i) => i.accountId?.startsWith("A"))?.accountId);
  accId = accId || "";

  const init = useRef({
    autoloadFailureNotification: !!customer.notification?.autoloadFailureNotification,
    loadAccountNotification: !!customer.notification?.loadAccountNotification,
    lowBalanceNotification: !!customer.notification?.lowBalanceNotification,
  });

  const [notif, setNotif] = useState(init.current);

  const { dispatch } = useLazyApiCall(
    apiServices.saveNotifications(accId),
    updateCustomersNotificationsEffect
  );

  useEffect(() => {
    const body = {
      autoloadFailureNotification: !!notif.autoloadFailureNotification,
      loadAccountNotification: !!notif.loadAccountNotification,
      lowBalanceNotification: !!notif.lowBalanceNotification,
    };
    const data = init.current;
    const prev = `${data?.autoloadFailureNotification ? 1 : 0}${
      data?.loadAccountNotification ? 1 : 0
    }${data?.lowBalanceNotification ? 1 : 0}`;
    const next = `${body.autoloadFailureNotification ? 1 : 0}${
      body.loadAccountNotification ? 1 : 0
    }${body.lowBalanceNotification ? 1 : 0}`;
    if (prev !== next) {
      init.current = body;
      dispatch({ body }, -1);
    }
  }, [dispatch, notif]);

  const handleChange = useCallback(
    (
      parameter:
        | "lowBalanceNotification"
        | "loadAccountNotification"
        | "autoloadFailureNotification"
    ) => (value: boolean) => {
      if (!accId) {
        message.error("Ooops, looks like you dont have any available account to update");
      }
      setNotif((s) => ({ ...s, [parameter]: value }));
    },
    [accId]
  );

  return (
    <Card padding="large">
      <Row>
        <Col className="d-flex justify-content-between" lg={12} xs={24}>
          <div className="d-block w-75">
            <Text>{t("NOTIFY_WHEN_ACCOUNT_AT_THRESHOLD")}</Text>
          </div>
          <Switch
            checked={notif.lowBalanceNotification}
            onChange={handleChange("lowBalanceNotification")}
          />
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col className="d-flex justify-content-between" lg={12} xs={24}>
          <div className="d-block w-75">
            <Text>{t("NOTIFY_WHEN_ACCOUNT_LOADED")}</Text>
          </div>
          <Switch
            checked={notif.loadAccountNotification}
            onChange={handleChange("loadAccountNotification")}
          />
        </Col>
      </Row>
      {!isVE && (
        <>
          <Divider />

          <Row>
            <Col className="d-flex justify-content-between" lg={12} xs={24}>
              <div className="d-block w-75">
                <Text>{t("NOTIFY_WHEN_AUTOLOAD_FAILED")}</Text>
              </div>
              <Switch
                checked={notif.autoloadFailureNotification}
                onChange={handleChange("autoloadFailureNotification")}
              />
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};
