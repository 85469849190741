import { getCurrency } from "./getCurrency";
import { getLocale } from "./getLocale";

export function formatPrice(price: number | string | undefined, currency?: string): string {
  const value = Number(price || 0) || 0;

  const format = new Intl.NumberFormat(getLocale(), {
    currency: currency || getCurrency(),
    style: "currency",
  });

  return format.format(value);
}
