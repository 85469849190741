import * as Yup from "yup";
import i18n from "i18next";

export function priceValidator(
  this: Yup.TestContext,
  value: string | null
): boolean | Yup.ValidationError {
  const { createError, path } = this;
  if (value && !/^[0-9.]*$/.test(value)) {
    return createError({
      message: i18n.t("PRICE_SHOULD_ONLY_NUMBER"),
      path,
    });
  }

  if (value && (/[.]{2,}/.test(value) || value?.split(".").length > 2)) {
    return createError({
      message: i18n.t("PRICE_CANNOT_CONTAIN_MORE_THAN_ONE_DELIMITER"),
      path,
    });
  }

  return true;
}

export function passwordValidator(
  this: Yup.TestContext,
  value: string | null
): boolean | Yup.ValidationError {
  const { createError, path } = this;

  if (value && /['\\$]+/.test(value)) {
    return createError({
      message: i18n.t("VALUE_CANNOT_CONTAIN_SPECIAL_CHAR"),
      path,
    });
  }

  return true;
}
