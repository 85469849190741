import { useCallback, useEffect, useState } from "react";

export function isMobile(): boolean {
  return window.innerWidth <= 480;
}

export function isDesktop(): boolean {
  return window.innerWidth > 480;
}

function useWatcher(method: () => boolean): boolean {
  const [value, set] = useState(method());

  const handleResize = useCallback(() => {
    set(method());
  }, [method]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return value;
}

export function useIsDesktop(): boolean {
  return useWatcher(isDesktop);
}

export function useIsMobile(): boolean {
  return useWatcher(isMobile);
}
