import { Api } from "api";
import { put } from "redux-saga/effects";
import { updateCustomer as updateCustomerAction } from "state/actions";

import { ApiValueError, ApiValueSuccess } from "state/AppState";

export function* updateCustomerEffect(
  update: ApiValueError | ApiValueSuccess<Api["Customer"]>
): unknown {
  if (update.ok && update.data) {
    yield put(updateCustomerAction(update.data));
  }
}
