import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { getHistory } from "helpers/history";
import { message } from "antd";
import { useLazyApiCall } from "../../state";
import { apiServices } from "../../helpers";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUrlParameter = (url: string, name: string) => {
  const regex = new RegExp(`[?&]${name}=([^&#]*)`);

  const results = regex.exec(url);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const EmailVerificationSuccess: FC = () => {
  const token = getUrlParameter(window.location.search, "token");

  const { dispatch, error, ok } = useLazyApiCall(apiServices.emailVerification(token));
  const { t } = useTranslation();
  React.useEffect(() => {
    dispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!ok && error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((error as any).includes("token expired")) {
        message.error(t("EMAIL_VERIFICATION_EXPIRED"));
      } else {
        message.error(error ?? t("SIGN_UP_FAILED"));
      }
      getHistory().pushWithTheme("/login");
    } else if (ok) {
      message.success(t("Sign up successful, please log in."));
      getHistory().pushWithTheme("/login");
    }
  }, [error, ok, t]);

  return <div />;
};
