import { Api } from "api";

export const customer = (): Api["Customer"] => {
  return {
    customerCode: "0001900",
    customerId: "0001900",
    email: "tom.hanks@google.com",
    firstName: "Tom",
    lastName: "Hanks",
  };
};
