import { Input } from "antd";
import { Form as AntForm } from "antd";
import { InputProps } from "antd/lib/input";
import React, { FC, ReactNode } from "react";

import "./textField.css";

const Item = AntForm.Item;

export interface Props extends Omit<InputProps, "value"> {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | null;
  onBlur?: () => void;
  helperText?: string;
  disabled?: boolean;
  label?: ReactNode;
  error?: boolean;
  name: string;
}

export const TextField: FC<Props> = ({ error, label, helperText, ...props }) => {
  return (
    <Item
      colon={false}
      help={helperText} // TODO: inspect this magic "::" - why one is removed
      label={!!label ? `${label}:` : undefined}
      labelCol={{ span: 24 }}
      validateStatus={error ? "error" : undefined}
    >
      {/* @ts-ignore TODO: ... */}
      <Input {...props} />
    </Item>
  );
};
