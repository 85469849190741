import { Api } from "api";
import { apiServices } from "helpers";
import { put } from "redux-saga/effects";
import { updateUser } from "state/actions";

import { ApiValueError, ApiValueSuccess } from "state/AppState";

import { fetchApi } from "./fetchApi";

export function* authResult(login: ApiValueError | ApiValueSuccess<Api["AuthResponse"]>): unknown {
  if (login.ok && login.data && login.data.accessToken) {
    const customer = yield fetchApi(apiServices.customer(login.data.accessToken)) as
      | ApiValueError
      | ApiValueSuccess<Api["Customer"]>;
    yield put(customer.ok ? updateUser(login.data, customer.data) : updateUser());
  }
}
