import { ConfigProvider, Layout, Menu, message } from "antd";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Link, Redirect, useLocation, useRouteMatch } from "react-router-dom";

import { t } from "helpers/translate";
import { Icon } from "components/Icon";
import { getHistory } from "helpers/history";
import { Text } from "components/Typography";
import { Container } from "components/Container";
import { useCustomer, useLoggedIn, useConfig, useLazyApiCall, updateCustomerEffect } from "state";
import { RenderDesktop, RenderMobile } from "components/RenderMatchMedia";

import logo from "assets/images/logo_light_bg.svg";

import { MobileMenu } from "./components/MobileMenu";
import { UserDropdown } from "./components/UserDropdown";

import styles from "./applayout.module.css";
import i18n from "i18next";
import { Select } from "antd";
import icon from "../../assets/images/language.svg";
import { apiServices } from "helpers";
import { parseBrowserLanguage } from "App";
import fr from "antd/es/locale/fr_FR";
import en from "antd/es/locale/en_US";
import moment from "moment";
import "moment/locale/fr";

const { Content, Header } = Layout;

/**
 * Auth Layout for sing-in / sign-up / reset password ...
 */
export const App: FC = ({ children }) => {
  const { pathname } = useLocation();
  const isLoggedIn = useLoggedIn();
  const user = useCustomer();
  const config = useConfig();
  const siteTheme = config.amsSite?.siteTheme;
  const reloadTitle = siteTheme?.labels?.["label.reload.title"];
  const {
    params: { theme },
  } = useRouteMatch<{ theme: string }>();

  const handleNavigate = useCallback((action: { key: keyof typeof navMap }) => {
    getHistory().pushWithTheme(navMap[action.key]);
  }, []);

  const currentPath = pathname.replace(new RegExp(`^/${theme}`), "").replace(/^\//, "");
  const activeItem = Object.keys(navMap).find((k) => currentPath.startsWith(k)) as
    | undefined
    | keyof typeof navMap;

  const [open, setOpen] = useState(false);
  const brand = useConfig().amsSite?.siteTheme?.logoNavUrl || logo;

  const isVE = config.connectorType === "VE";

  const { dispatch, error } = useLazyApiCall(apiServices.updateProfile(), updateCustomerEffect);

  const setCustomerLanguage = useCallback(
    (value: string) => {
      dispatch(
        {
          body: {
            locale: value,
          },
        },
        1
      );
    },
    [dispatch]
  );

  useEffect(() => {
    // Check user's language preference (in VE) and if user has selected a language which is different than browser language then change it.
    if (isVE && user?.locale && user?.locale !== parseBrowserLanguage(i18n.language)) {
      i18n.changeLanguage(user.locale);
    }
  }, [user?.locale, isVE]);

  useEffect(() => {
    moment.locale(parseBrowserLanguage(i18n.language));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    error && message.error(error);
  }, [error]);

  if (!isLoggedIn) {
    return <Redirect to={`/${theme}/login`} />;
  }

  return (
    <ConfigProvider locale={parseBrowserLanguage(i18n.language) === "en" ? en : fr}>
      <Layout className={styles.layout}>
        <Header className={styles.header}>
          {/* Desktop menu */}
          <RenderDesktop>
            <Container className="d-flex flex-row align-items-center p-0">
              <Link className="mr-5" title={t("VOLANTE")} to={`/${theme}/wallet-dashboard`}>
                {/* eslint-disable */}
                <img
                  alt="Volante brand logo"
                  src={brand}
                  style={{ maxWidth: "100%", maxHeight: 200, padding: "24px 0" }}
                />
              </Link>

              <Menu
                className="flex-grow-1"
                mode="horizontal"
                selectedKeys={[activeItem || ""]}
                // @ts-ignore TODO: inspect ...
                onClick={handleNavigate}
              >
                <Menu.Item className={styles.menuItem} key="wallet-dashboard">
                  {i18n.t("WALLET")}
                </Menu.Item>
                <Menu.Item className={styles.menuItem} key="reload">
                  {reloadTitle ? reloadTitle : i18n.t("RELOAD")}
                </Menu.Item>
              </Menu>

              <UserDropdown user={user} />
              <Select
                defaultValue={parseBrowserLanguage(i18n.language)}
                size="large"
                style={{ marginLeft: 12, width: 150 }}
                value={parseBrowserLanguage(i18n.language)}
                onSelect={(val) => {
                  i18n.changeLanguage(val);
                  if (isVE) {
                    setCustomerLanguage(val);
                  }
                }}
              >
                <Select.Option value={"en"}>
                  <div className={styles.languageOption}>
                    <span>{i18n.t("ENGLISH")}</span>
                    <img alt="" className={styles.langIcon} src={icon} />
                  </div>
                </Select.Option>
                <Select.Option value={"fr"}>
                  <div className={styles.languageOption}>
                    <span>{i18n.t("FRENCH")}</span>
                    <img alt="" className={styles.langIcon} src={icon} />
                  </div>
                </Select.Option>
              </Select>
            </Container>
          </RenderDesktop>

          {/* Mobile menu */}
          <RenderMobile>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Link className="mr-2 p-3" title={t("VOLANTE_AMS")} to={`/${theme}/wallet-dashboard`}>
                <img
                  alt="Volante brand logo"
                  src={brand}
                  style={{ maxHeight: 200, maxWidth: "100%", padding: "24px 0" }}
                />
              </Link>

              <Text color="disabled">
                <Icon className="p-3 d-flex" name="menu" size={24} onClick={() => setOpen(true)} />
              </Text>
            </div>
          </RenderMobile>
        </Header>

        {/* Content */}
        <Content className={styles.content}>
          <Container>{children}</Container>
        </Content>
      </Layout>
      <RenderMobile>
        <MobileMenu
          activeItem={activeItem}
          open={open}
          user={user}
          onClose={() => setOpen(false)}
        />
      </RenderMobile>
    </ConfigProvider>
  );
};

const navMap = {
  profile: "/profile",
  reload: "/reload",
  "wallet-dashboard": "/wallet-dashboard",
};
