import cn from "classnames";
import React, { FC } from "react";
import { Card as AntCard } from "antd";
import type { CardProps } from "antd/lib/card";

import styles from "./card.module.css";
import { isDesktop } from "helpers/isMobile";

interface Props extends CardProps {
  padding?: "large" | "normal" | "small" | "none";
  radius?: "normal" | "none";
  className?: string;
  elevated?: boolean;
}

export const Card: FC<Props> = ({
  radius = "normal",
  className,
  children,
  elevated,
  padding,
  ...props
}) => {
  const pad = padding === "none" || isDesktop() ? padding : "normal";

  return (
    <AntCard
      {...props}
      className={cn(styles.card, className, {
        [styles.normalRadius]: radius === "normal",
        [styles.noRadius]: radius === "none",
        [styles.largeSpace]: pad === "large",
        [styles.noSpace]: pad === "none",
        [styles.smallPadding]: pad === "small",
        [styles.elevated]: elevated,
      })}
    >
      {children}
    </AntCard>
  );
};
