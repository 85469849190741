import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useRef } from "react";

import { parse } from "helpers";
import { Input } from "antd";
import { useConfig } from "../../state";
import styles from "./freedompay.module.css";

export interface FreedomResponse {
  paymentKey: string;
  paymentType: "Card";
  attributes: Record<"CardIssuer" | "MaskedCardNumber" | "ExpirationDate" | "PostalCode", string>;
  sessionKey: string;
  nameOnCard?: string;
}

interface Props {
  onSuccess?: (res: FreedomResponse) => void;
  onRefresh?: () => void;
  // its not url for real, its html tag
  url?: string;
}

export const FreedomPay: FC<Props> = ({ url, onRefresh, onSuccess }) => {
  const onSuccessRef = useRef(onSuccess);
  const { t } = useTranslation();
  const loaded = true;
  const config = useConfig();
  const isVE = config.connectorType === "VE";
  const [name, setName] = React.useState("");

  useEffect(() => {
    onSuccessRef.current = onSuccess;

    return () => {
      onSuccessRef.current = undefined;
    };
  }, [onSuccess]);

  const params: Record<string, string> = {};

  (url || "")
    .replace("</iframe>", "")
    .replace("<iframe", "")
    .replace('">', "")
    .trim()
    .split('" ')
    .map((i) =>
      i
        .trim()
        .split('="')
        .map((i) => i.trim())
    )
    .forEach((i) => (params[i[0]] = i[1]));

  const sessionKey = (parse((params.src || "").replace(/^htt.*\?/, "")).sessionKey || "") as string;

  useEffect(() => {
    function handleMessage(e: MessageEvent) {
      const message = e.data;
      const data = message.data;

      // handle errors
      if (message.type === 1) {
        // Ask for new session once old expired
        onRefresh && onRefresh();
      }

      if (message.type !== 3 || !data.paymentKeys || data.paymentKeys.lenght === 0) {
        return;
      }

      onSuccessRef.current &&
        onSuccessRef.current({
          // @ts-ignore TODO: fix typing
          attributes: data.attributes.reduce(
            (acc: FreedomResponse, it: { Key: string; Value: string }) => ({
              ...acc,
              [it.Key]: it.Value,
            }),
            {} as FreedomResponse
          ) as FreedomResponse,
          //For V7, since the input field is not rendered, "" is sent for nameOnCard which BE allows.
          nameOnCard: name,
          paymentKey: data.paymentKeys[0],
          paymentType: data.paymentType,
          sessionKey,
        });
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [onRefresh, sessionKey, name]);

  return loaded ? (
    params.src ? (
      <>
        {isVE && (
          <>
            <div className={styles.nameOnCardLabel}>{t("CARDHOLDER_NAME")}</div>
            <Input
              required
              className={styles.nameOnCard}
              placeholder={t("CARDHOLDER_NAME")}
              value={name}
              // according to FP this field should only allow alpha characters and space
              onChange={(e) => setName(e.target.value.replace(/[^a-z ]/gi, ""))}
            />
          </>
        )}
        {(!isVE || (name !== "" && isVE)) && (
          <iframe
            frameBorder="no"
            height="500"
            scrolling="no"
            src={params.src}
            title="pay"
            width="100%"
          />
        )}
      </>
    ) : (
      <div className="p-5 text-center">{t("ERROR_GETTING_FP_FRAME")}</div>
    )
  ) : (
    <div className="p-5 text-center">{t("LOADING_PAYMENT_PROVIDER")}</div>
  );
};

// function submitPaymentRequest(paymentKey) {
//   toggleOverlay(true);

//   // NOTE: This should happen on the server, however, for testing, we are using an AJAX POST to submit the payment
//   var requestData = {
//       PaymentKey: paymentKey,
//       RequestMessage: getPaymentRequestMessage(),
//     },
//     headers = {
//       Authorization: "Bearer " + sessionKey,
//     };

//   if (requestType === "TokenPayment") requestData.TokenInformation = tokenInformation;

//   if (version !== "v1.1" && version !== "v1.2") {
//     requestData.PosSyncAttemptNum = posSyncAttemptNum;
//     requestData.PosSyncId = posSyncId;
//   }

//   if (version === "v1.5") {
//     // TODO add ccAuth data
//     requestData.CcAuth = "something";
//   }

//   $.ajax({
//     type: "POST",
//     url: "https://hpc.uat.freedompay.com/api/" + version + "/payments",
//     data: requestData,
//     dataType: "json",
//     headers: headers,
//   })
//     .done(function (data, textStatus, jqXhr) {
//       if (requestType === "ApplePayPayment")
//         window.top.document.dispatchEvent(new CustomEvent("ApplePayFinalize", { detail: true }));

//       handleNotificationDialog(jqXhr, data);
//     })
//     .fail(function (jqXhr) {
//       if (requestType === "ApplePayPayment")
//         window.top.document.dispatchEvent(new CustomEvent("ApplePayFinalize", { detail: false }));

//       handleNotificationDialog(jqXhr);
//     })
//     .always(function () {
//       toggleOverlay(false);
//     });
// }
