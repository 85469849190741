import cn from "classnames";
import { Col, DatePicker, Space, Tooltip } from "antd";
import { TableProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useMemo, useState } from "react";

import { Api, Account } from "api";
import { Row } from "components/Grid";
import { Icon } from "components/Icon";
import { Card } from "components/Card";
import { Table } from "components/Table";
import { Button } from "components/Button";
import { Select } from "components/Select";
import { formatDate } from "helpers/formatDate";
import { downloadCsv } from "helpers/downloadCsv";

import { PrintPrice } from "../PrintPrice";

import "./transactionsTable.css";
import styles from "./transactionsTable.module.css";
import { PrintModal } from "./PrintModal";
import { apiServices, DEFAULT_PAGE_SIZE, EXPORT_PAGE_SIZE, formatPrice } from "helpers";
import { TFunction } from "i18next";
import { useConfig, useLazyApiCall } from "state";
import moment from "moment";
interface Props {
  isLoading: boolean;
  clientFullName: string;
  filter: ITransactionFilter;
  accounts: Account[];
  data: Api["TransSummary"][];
  onChangeFilter: (val: ITransactionFilter) => void;
  onSelect: (val: Api["TransSummary"]) => void;
  totalLength: number;
}

export interface ITransactionFilter {
  filterAccountId?: string;
  filterEndTime?: string;
  filterStartTime?: string;
  page?: number;
}

export const TransactionsTable: FC<Props> = ({
  clientFullName,
  onChangeFilter,
  isLoading,
  totalLength,
  onSelect,
  accounts,
  filter,
  data,
}) => {
  const { t } = useTranslation();
  const config = useConfig();
  const isVE = config.connectorType === "VE";
  const [print, setPrint] = useState(false);
  const [csvDownloaded, setCsvDownloaded] = useState(false);
  const filterOptions = [
    { label: React.createElement("span", {}, t("ALL_ACCOUNTS")), value: "all" },
  ].concat(
    accounts.map((i) => ({
      label: React.createElement(
        "div",
        {},
        <span>
          {i.accountName}
          <span className={styles.filterAccountId}>({i.accountDisplayId ?? i.accountId})</span>
        </span>
      ),
      value: i.accountId || "",
    }))
  );

  const { dispatch, ok, data: allRes, isLoading: allIsLoading } = useLazyApiCall(
    apiServices.transactions(accounts.map((i) => i.accountId || "").toString())
  );

  const handleExport = async () => {
    setCsvDownloaded(false);
    // get the date for 30 days ago
    const startTime = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString();
    dispatch(
      apiServices.transactions(
        accounts
          .filter(
            (i) =>
              !filter.filterAccountId ||
              filter.filterAccountId === "all" ||
              i.accountId === filter.filterAccountId ||
              filter.filterAccountId.replace(/^[A-Z]/, "") === i.accountId
          )
          .map((i) => i.accountId || "")
          .toString(),
        EXPORT_PAGE_SIZE,
        undefined,
        startTime
      ),
      -1
    );
  };

  useEffect(() => {
    if (
      !csvDownloaded &&
      !allIsLoading &&
      !isLoading &&
      ok &&
      allRes &&
      allRes.size === EXPORT_PAGE_SIZE
    ) {
      // remove comma for all to keep the csv format right
      const csvData: string[][] = allRes.content.map((i: Api["TransSummary"]) => [
        formatDate(i.transDate || new Date(), "csvExportDate"),
        (i.terminal || "").replace(",", "") || t("ACCOUNT_UPDATE"),
        findAccountName(i.accountId, accounts).replace(",", ""),
        formatAmount(i.accountId, accounts, i.amount, t).toString().replace(",", ""),
      ]);
      const header = [t("DATE"), t("LOCATION"), t("ACCOUNTS"), t("AMOUNT")];
      downloadCsv(
        `TransactionHistory${clientFullName.trim().replace(/[^a-zA-Z]/, "")}${formatDate(
          new Date(),
          "filename"
        )}.csv`,
        [header, ...csvData]
      );
      setCsvDownloaded(true);
    }
  }, [allRes, allIsLoading, isLoading, ok, accounts, clientFullName, t, csvDownloaded]);

  const cols: TableProps<Api["TransSummary"]>["columns"] = useMemo(
    () => [
      {
        className: styles.typeCol,
        dataIndex: "transId",
        key: "transId",
        // @ts-ignore TODO: add all icons
        render: (_value: Api["TransSummary"]["transId"], record: Api["TransSummary"]) => {
          const type = transactionType(record);
          return (
            <Tooltip placement="bottom" title={t("PURCHASE_TYPE", { type })}>
              <div className={styles.typeIcon}>
                <Icon name={typeToIcon[type]} />
              </div>
            </Tooltip>
          );
        },
        title: undefined,
        width: "64px", // TODO: ...
      },
      {
        dataIndex: "transDate",
        key: "transDate",
        render: (value: string) => formatDate(value, "longDate"),
        title: t("DATE"),
      },
      {
        dataIndex: "terminal",
        key: "terminal",
        render: (value: string, record) => {
          let val = value ?? t("ACCOUNT_UPDATE");
          if (isVE && record?.employee === "Back Office") {
            val = `${t("BO")} | ${val}`;
          }
          return val;
        },
        title: t("LOCATION"),
      },
      {
        dataIndex: "accountId",
        key: "accountId",
        render: (id: ID) => findAccountName(id, accounts),
        title: t("ACCOUNTS"),
      },
      {
        dataIndex: "amount",
        key: "amount",
        render: (value: string | number, record) => {
          const accountType = getAccountType(record.accountId, accounts);
          return accountType !== "MEAL_PLAN_ACCOUNT" && accountType !== "POINTS_ACCOUNT" ? (
            <PrintPrice value={value} />
          ) : (
            <span className={cn(Number(value) >= 0 ? styles.greenText : "")}>
              {accountType === "MEAL_PLAN_ACCOUNT"
                ? t("MEAL_NUMBER_PLURAL", {
                    balance: value,
                  })
                : t("POINTS_PLURAL", {
                    balance: value,
                  })}
            </span>
          );
        },
        title: t("AMOUNT"),
      },
    ],
    [accounts, t, isVE]
  );

  const dataSet = useMemo(() => data.map((it, index) => ({ ...it, key: `it.id-${index}` })), [
    data,
  ]);

  return (
    <>
      <Row className="mb-4">
        <Col className={"mr-2"} span={6}>
          <Row>
            <div>{t("ACCOUNTS")}</div>
          </Row>
          <Row>
            <Select
              // @ts-ignore TODO: ...
              defaultValue={filter.filterAccountId}
              key={filter.filterAccountId}
              // @ts-ignore TODO: ... antd type problem
              options={filterOptions}
              size="large"
              value={filter.filterAccountId}
              onSelect={(val) => {
                onChangeFilter({ filterAccountId: val, page: 0 });
              }}
            />
          </Row>
        </Col>
        <Col className={cn("ml-auto", "mr-auto")} span={10}>
          <Row>
            <Col className={"w-50"}>
              <div>{t("START_DATE")}</div>
            </Col>
            <Col className={"w-50"}>
              <div>{t("END_DATE")}</div>
            </Col>
          </Row>
          <Row>
            <DatePicker.RangePicker
              allowClear={false}
              className={cn("w-100", styles.buttonHeight)}
              disabled={isLoading}
              disabledDate={(current) => current && current > moment().endOf("day")}
              picker={"date"}
              value={[moment(filter.filterStartTime), moment(filter.filterEndTime)]}
              onCalendarChange={(_, stringDates) => {
                onChangeFilter({
                  filterEndTime: moment(stringDates[1]).toISOString(),
                  filterStartTime: moment(stringDates[0]).toISOString(),
                  page: 0,
                });
              }}
            />
          </Row>
        </Col>
        <Col className={"mt-auto"} span={6}>
          <Space>
            <Tooltip placement="bottom" title={t("PRINT_TRANS_HISTORY")}>
              <Button type="secondary" onClick={() => setPrint(true)}>
                {t("PRINT")}
              </Button>
            </Tooltip>

            <Tooltip placement="bottom" title={t("EXPORT_TRANS_HISTORY_IN_CSV")}>
              <Button type="secondary" onClick={handleExport}>
                {t("EXPORT")}
              </Button>
            </Tooltip>
          </Space>
        </Col>
      </Row>
      <Row>
        <Card elevated className="w-100" padding="none">
          <Table<Api["TransSummary"]>
            className={"trans-table"}
            // @ts-ignore TODO: improve typings
            columns={cols}
            dataSource={dataSet}
            loading={isLoading}
            pagination={
              filter.page !== undefined
                ? {
                    current: filter.page + 1,
                    onChange: (page) => {
                      onChangeFilter({ page: page - 1 });
                    },
                    pageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: false,
                    style: { marginRight: "10px" },
                    total: totalLength,
                  }
                : undefined
            }
            // @ts-ignore TODO: use generic if antd supports it...
            onRow={(record: TransactionItem) => ({
              onClick: record.transId ? () => onSelect(record) : undefined,
            })}
          />
        </Card>
      </Row>

      <PrintModal
        clientFullName={clientFullName}
        cols={cols.slice(1)}
        data={dataSet}
        isOpen={print}
        onRequestClose={() => setPrint(false)}
      />
    </>
  );
};

/**
 * Map transaction type to icon
 */
const typeToIcon = {
  CREDIT: "sync",
  DEBIT: "tag",
  SHARED: "team",
} as const;

/**
 * Compute transaction type
 *
 * For now, this is how to tell (if there's a change, will let you know for sure):
 * 1. refund on POS - "terminal"/"transId" not empty and amount is positive
 * 2. sale/debit on POS - "terminal"/"transId" not empty and amount is negative
 * 3. credit on BO - "terminal"/"transId" empty and amount is positive
 * 4. debit on BO - "terminal"/"transId" empty and amount is negative
 * 5. shared or not - determined by "sharedTrans" = true/false for the above 4 cases
 */
function transactionType(transaction: Api["TransSummary"]): keyof typeof typeToIcon {
  const { type, sharedTrans } = transaction;
  if (sharedTrans) {
    return "SHARED";
  }
  return type;
}

function getAccountType(accountId: string, accounts: Account[]) {
  return accounts.find((i) => {
    return i.accountId === accountId;
  })?.accountType;
}

function findAccountName(accountId: string, accounts: Account[]) {
  return (
    accounts.find((i) => {
      return i.accountId === accountId;
    })?.accountName || ""
  );
}

function formatAmount(
  accountId: string,
  accounts: Account[],
  value: string | number,
  t: TFunction
) {
  const accountType = getAccountType(accountId, accounts);
  return accountType !== "MEAL_PLAN_ACCOUNT" && accountType !== "POINTS_ACCOUNT"
    ? formatPrice(value)
    : accountType === "MEAL_PLAN_ACCOUNT"
    ? t("MEAL_NUMBER_PLURAL", {
        balance: value,
      })
    : t("POINTS_PLURAL", {
        balance: value,
      });
}
