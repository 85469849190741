import * as Yup from "yup";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { Auth as AuthLayout } from "layouts";
import { getHistory } from "helpers/history";
import { Form, Field } from "components/Form";
import { passwordValidator } from "helpers/yup";
import { Text, Title } from "components/Typography";
import { useQueryParams } from "helpers/queryString";
import { PasswordField } from "components/Form/fields/PasswordField";

export const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const { token } = useQueryParams();

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          // TODO: add password validation once we get merged with martin's pwd field
          confirmPassword: Yup.string()
            .required()
            .nullable()
            .test(
              "same",
              t("PASSWORDS_DONT_MATCH"),
              function validateSamePwd(value: string | null | undefined): boolean {
                return !value || this.parent.newPassword === value;
              }
            )
            .test(passwordValidator as never) // TODO: inspect hot wo type validator
            .default(null),
          newPassword: Yup.string()
            .required()
            .test(passwordValidator as never)
            .nullable()
            .default(null),
        })
        .defined(),
    [t]
  );

  type Data = Yup.InferType<typeof schema>;

  const initialValues = useMemo(() => schema.cast({}), [schema]);

  const handleSubmit = () => {
    // eslint-disable-next-line no-console
    console.log(token);
    getHistory().pushWithTheme(`/reset-password-success`);
  };

  return (
    <AuthLayout>
      <Form<Data>
        id="loginForm"
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <div className="d-block flex-col">
          <Title className="mb-3 d-block" level={1}>
            {t("RESET_PASSWORD")}
          </Title>
          <Text className="mb-5 d-block">{t("NEW_PASSWORD_MUST_BE_DIFFERENT")}</Text>

          <Field
            autoComplete="off"
            component={PasswordField}
            label={t("NEW_PASSWORD")}
            name="newPassword"
            placeholder={t("PASSWORD")}
          />

          <Field
            autoComplete="off"
            component={PasswordField}
            label={t("CONFIRM_PASSWORD")}
            name="confirmPassword"
            placeholder={t("PASSWORD")}
          />

          <Button block className="mt-4" htmlType="submit" type="primary">
            {t("RESET_PASSWORD")}
          </Button>
        </div>
      </Form>
    </AuthLayout>
  );
};
