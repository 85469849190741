import cn from "classnames";
import React, { FC } from "react";
import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";

import styles from "./typography.module.css";

const AntText = Typography.Text;

interface Props extends TextProps {
  className?: string;
  color?: "primary" | "success" | "disabled" | "error";
  size?: "large" | "medium" | "small" | "normal";
  line?: "large";
}

export const Text: FC<Props> = ({
  children,
  className,
  color,
  size = "normal",
  line,
  ...props
}) => {
  return (
    <AntText
      {...props}
      className={cn(className, {
        [styles.textPrimary]: color === "primary",
        [styles.textError]: color === "error",
        [styles.textSuccess]: color === "success",
        [styles.textDisabled]: color === "disabled",
        [styles.largeLine]: line === "large",
        [styles.largeFont]: size === "large",
        [styles.mediumFont]: size === "medium",
        [styles.smallFont]: size === "small",
        [styles.normalFont]: size === "normal",
      })}
    >
      {children}
    </AntText>
  );
};
