import { select, put, spawn } from "redux-saga/effects";

import { Api } from "api";
import { apiServices, logger } from "helpers";

import { fetchApi } from "./fetchApi";
import { mergeConfig } from "../helpers";
import { refreshCustomer } from "./refreshCustomer";
import { ApiValueSuccess, AppState } from "../AppState";
import { initialized as initDoneAction } from "../actions";

export function* init(): Generator {
  const { initialized, rememberMe, user, config } = (yield select((s) => s)) as AppState;

  if (initialized) {
    return;
  }

  if (rememberMe && user) {
    try {
      // refresh customer
      yield spawn(refreshCustomer);
    } catch (e) {
      logger.error(e);
    }
  }

  const siteConf = yield fetchApi(apiServices.site(config.name)) as ApiValueSuccess<Api["SiteWeb"]>;
  // @ts-ignore TODO:
  const conf = mergeConfig(config, siteConf.data);

  yield put(initDoneAction(conf));
}
