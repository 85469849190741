import { Api } from "api";

import { ApiValue, AppState } from "../AppState";

/**
 * Initialize Api Call status
 * @param path api route path - state identifier
 */
export const apiCallStart = (path: string, keepOld?: boolean) =>
  ({ keepOld, path, type: "START_API_CALL" } as const);

/**
 * Clear Api Call status
 * @param path api route path - state identifier
 */
export const clearApiCall = (path: string) => ({ path, type: "CLEAR_API_CALL" } as const);

/**
 * Write Api Call state to store
 * @param path api route path - state identifier
 * @param result response
 */
export const resolveApiCall = <T = never>(path: string, response: ApiValue<T>) =>
  ({ path, response, type: "RESOLVE_API_CALL" } as const);

/**
 * Set session user object
 * @param user
 */
export const updateUser = (user?: AppState["user"], customer?: AppState["customer"]) =>
  ({ customer, type: "UPDATE_USER", user } as const);

/**
 * Trigger when app is ready
 */
export const initialized = (config?: Api["SiteWeb"]) => ({ config, type: "INITIALIZED" } as const);

export const updateCustomer = (customer: AppState["customer"]) =>
  ({ customer, type: "UPDATE_CUSTOMER" } as const);

/**
 * indicate weather JWT TOKEN is refreshing
 */
export const refreshTokenStart = () => ({ type: "REFRESH_TOKEN_START" } as const);

/**
 * indicate weather JWT TOKEN refreshing is done
 */
export const refreshTokenDone = () => ({ type: "REFRESH_TOKEN_DONE" } as const);

export const rememberLogin = (value: boolean) =>
  ({ type: "REMEMBER_LOGIN", value: value ? true : undefined } as const);
