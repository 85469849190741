// core
import React, { forwardRef } from "react";
// api
import { components } from "api-schema";
// components
import { Col, Divider, Row } from "antd";
// libraries
import cn from "classnames";
import { useTranslation } from "react-i18next";
// styles
import "./receipt.css";

interface Props {
  transactionId?: ID;
  receipt?: string | components["schemas"]["Printing"];
  error?: Error | string;
  isLoading: boolean;
}

export const Receipt = forwardRef<HTMLPreElement | HTMLDivElement, Props>(
  ({ receipt, error, isLoading }, ref) => {
    const { t } = useTranslation();
    /**
     * If the receipt is an object and the connector type is VE then display the VE receipt
     */
    return isLoading ? (
      <pre
        ref={ref as React.ForwardedRef<HTMLPreElement>}
        className={cn("receipt-container", "border rounded p-3")}
      >
        {t("LOADING_RECEIPT")}
      </pre>
    ) : typeof receipt === "object" ? (
      // When type of receipt is object then it's a VE receipt
      <ReceiptTemplateVE ref={ref as React.ForwardedRef<HTMLDivElement>} receipt={receipt} />
    ) : (
      <pre
        ref={ref as React.ForwardedRef<HTMLPreElement>}
        className={cn("receipt-container", "border rounded p-3")}
      >
        {receipt || error}
      </pre>
    );
  }
);

const ReceiptTemplateVE = forwardRef<
  HTMLDivElement,
  { receipt: components["schemas"]["Printing"] }
>((props, ref) => {
  const receipt: components["schemas"]["Printing"] = props.receipt ?? [];
  return (
    <>
      <div ref={ref} className="ve-receipt-container" key={receipt.totalWidth}>
        <ReceiptDisplayDiv receipt={receipt} />
      </div>
    </>
  );
});

const computeAlignment = (
  alignment: components["schemas"]["PrintingStyle"]["alignment"] | undefined
): "left" | "right" | "center" => {
  if (!alignment) {
    return "left";
  }
  switch (alignment) {
    case "CENTER":
      return "center";
    case "RIGHT":
      return "right";
    case "LEFT":
    default:
      return "left";
  }
};

const computeFont = (font: components["schemas"]["PrintingStyle"]["font"] | undefined) => {
  switch (font) {
    case "SMALL":
      return "12px";
    case "HUGE":
      return "16px";
    case "NORMAL":
    default:
      return "14px";
  }
};
const computeHeight = (font: components["schemas"]["PrintingStyle"]["font"] | undefined) => {
  switch (font) {
    case "SMALL":
      return "14px";
    case "HUGE":
      return "18px";
    case "NORMAL":
    default:
      return "16px";
  }
};

const ReceiptLine = (props: {
  line: components["schemas"]["PrintingRow"];
  originalWidth: number;
}) => {
  const { line, originalWidth } = props;
  let columns = line.columns?.filter(
    (column) => column.type === "TEXT" || column.type === "DIVIDER"
  );
  if (!columns || columns.length === 0) {
    return null;
  }

  const width = 24;
  let usedWidth = 0;
  let autoGrowColumns = 0;
  let lastAutoGrowColumnIndex = -1;
  columns = columns.map((column, index) => {
    let span;
    if (column.width) {
      span = Math.round((column.width / originalWidth) * width);
      usedWidth += span;
    } else {
      autoGrowColumns++;
      lastAutoGrowColumnIndex = index;
    }
    return {
      ...column,
      width: span,
    };
  });

  const autoGrowColumnWidth = Math.round((width - usedWidth) / autoGrowColumns);
  const diff = width - usedWidth - autoGrowColumns * autoGrowColumnWidth;

  const finalColumns = columns.map((column, index) => {
    const columnWidth =
      column.width ||
      (lastAutoGrowColumnIndex === index ? autoGrowColumnWidth + diff : autoGrowColumnWidth);
    return {
      ...column,
      width: columnWidth,
    };
  });

  return columns ? (
    <Row>
      {finalColumns.map((column, index) => {
        const alignment = computeAlignment(column.style?.alignment);
        const fontSize = computeFont(column.style?.font);
        return (
          <Col
            key={index}
            span={column.width}
            style={{
              fontSize: fontSize,
              fontWeight: column.style?.bold ? "bold" : "normal",
              minHeight: computeHeight(column.style?.font),
              textAlign: alignment,
            }}
          >
            {column.type === "TEXT" ? (
              column.value || ""
            ) : column.value ? (
              // eslint-disable-next-line no-inline-styles/no-inline-styles
              <Divider dashed style={{ background: "black" }} />
            ) : (
              // eslint-disable-next-line no-inline-styles/no-inline-styles
              <Divider style={{ borderColor: "transparent" }} />
            )}
          </Col>
        );
      })}
    </Row>
  ) : null;
};

const ReceiptDisplayDiv = (props: { receipt: components["schemas"]["Printing"] }) => {
  const { receipt } = props;
  const lines = receipt.lines || [];
  return (
    <div>
      {lines.map((line, index) => (
        <ReceiptLine key={index} line={line} originalWidth={receipt.totalWidth || 42} />
      ))}
    </div>
  );
};
