export function userFullName(user?: {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
}): string {
  if (!user) {
    return "";
  }

  return (
    [user.firstName, user.middleName, user.lastName].filter(Boolean).join(" ").trim() ||
    user.email ||
    ""
  );
}
