import * as Yup from "yup";
import { message, Select } from "antd";
import { useTranslation } from "react-i18next";
import React, { FC, RefObject, useCallback, useEffect, useMemo, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "components/Link";
import { Button } from "components/Button";
import { Auth as AuthLayout } from "layouts";
import { passwordValidator } from "helpers/yup";
import { apiServices, getHistory } from "helpers";
import { getConfig, useConfig, useLazyApiCall } from "state";
import { Text, Title } from "components/Typography";
import { PasswordField } from "components/Form/fields/PasswordField";
import { CheckboxField, Field, Form, TextField } from "components/Form";
import { RenderDesktop, RenderMobile } from "../../components/RenderMatchMedia";
import i18n from "i18next";
import styles from "./common.module.css";
import icon from "../../assets/images/language.svg";
import { parseBrowserLanguage } from "App";

export const GuestSignUpPage: FC = () => {
  const { dispatch, error, ok, isLoading, data } = useLazyApiCall(apiServices.guestSignUp());
  const { t } = useTranslation();
  const config = useConfig();
  const isVE = config.connectorType === "VE";
  const siteTheme = config.amsSite?.siteTheme;
  const signupLabel = siteTheme?.labels?.["label.auth.signup"];
  const loginButtonText = siteTheme?.labels?.["button.auth.login"];
  const termsLink = getConfig().amsSite?.term;

  const [currentLang, setCurrentLang] = useState(parseBrowserLanguage(i18n.language));
  const [captchaToken, setCaptchaToken] = useState(null as string | null);
  const recaptchaRef = React.createRef() as RefObject<ReCAPTCHA>;

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          confirmPassword: Yup.string()
            .required(t("FIELD_REQUIRED"))
            .test(passwordValidator as never)
            .nullable()
            .test("same", t("PASSWORD_DOESNT_MATCH"), function samePasswords(value): boolean {
              return !value || value === this.parent.password;
            })
            .default(null),
          email: Yup.string()
            .email(t("ENTER_VALID_EMAIL"))
            .required(t("FIELD_REQUIRED"))
            .nullable()
            .default(null),
          firstName: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/, t("INVALID_FORMAT"))
            .required(t("FIELD_REQUIRED"))
            .nullable()
            .default(null),
          lastName: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/, t("INVALID_FORMAT"))
            .required(t("FIELD_REQUIRED"))
            .nullable()
            .default(null),
          password: Yup.string()
            .required(t("FIELD_REQUIRED"))
            .test(passwordValidator as never)
            .nullable()
            .default(null),
          rememberMe: Yup.boolean().default(false),
          tcAgreement: Yup.boolean()
            .test("mustAgree", t("READ_TERMS_CONDITIONS"), Boolean)
            .default(false),
        })
        .defined(),
    [t]
  );

  const initialValues = useMemo(() => schema.cast({}), [schema]);

  type FormData = Yup.InferType<typeof schema>;

  const handleSubmit = useCallback(
    (data: FormData) => {
      const { firstName, lastName, password, email } = data;
      const guest = {
        captchaToken: captchaToken,
        email: email || "",
        firstName: firstName,
        lastName: lastName,
        password: password || "",
        userType: "GUEST",
      };
      // localStorage.setItem("guest", JSON.stringify(guest));

      dispatch(
        {
          body: guest,
        },
        1
      );
    },
    [captchaToken, dispatch]
  );

  useEffect(() => {
    if (error) {
      recaptchaRef.current?.reset();
      if (((error as unknown) as string).includes("Email address already in use")) {
        message.error(t("EMAIL_ADDRESS_IN_USE"));
      } else if (((error as unknown) as string).includes("Failed to validate captcha token")) {
        message.error(t("FAILED_TO_VALIDATE_CAPTCHA"));
      } else {
        message.error(t("SIGN_UP_FAILED"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isVE, t]);

  useEffect(() => {
    if (ok) {
      localStorage.setItem("guest", JSON.stringify(data));
      getHistory().pushWithTheme("/guest-success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ok, t]);

  return (
    <>
      <RenderDesktop>
        <Select
          className={styles.languageSelectorDropdown}
          defaultValue={currentLang}
          size="large"
          value={currentLang}
          onSelect={(val) => {
            setCurrentLang(val);
            i18n.changeLanguage(val);
          }}
        >
          <Select.Option value={"en"}>
            <div className={styles.languageOption}>
              <span>{i18n.t("ENGLISH")}</span>
              <img alt="" className={styles.langIcon} src={icon} />
            </div>
          </Select.Option>
          <Select.Option value={"fr"}>
            <div className={styles.languageOption}>
              <span>{i18n.t("FRENCH")}</span>
              <img alt="" className={styles.langIcon} src={icon} />
            </div>
          </Select.Option>
        </Select>
      </RenderDesktop>

      <RenderMobile>
        <div className={styles.languageSelectContainer}>
          <div className={styles.currentLanguage}>
            <img alt="" className={styles.langIcon} src={icon} />
            <span>{i18n.language}</span>
          </div>

          <Button
            className={"changeLanguage"}
            type={"link"}
            onClick={() => {
              i18n.changeLanguage(parseBrowserLanguage(i18n.language) === "fr" ? "en" : "fr");
            }}
          >
            {t("CHANGE_LANGUAGE_TO", {
              language: t(parseBrowserLanguage(i18n.language) === "fr" ? "ENGLISH" : "FRENCH"),
            })}
          </Button>
        </div>
      </RenderMobile>

      <AuthLayout>
        <Form
          id="loginForm"
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <div className="d-block flex-col">
            <Title className="mb-5" level={1}>
              {signupLabel ? signupLabel : t("SIGN_UP")}
            </Title>
            <Field
              component={TextField}
              label={t("FIRST_NAME")}
              name="firstName"
              placeholder={t("FIRST_NAME")}
            />
            <Field
              component={TextField}
              label={t("LAST_NAME")}
              name="lastName"
              placeholder={t("LAST_NAME")}
            />
            <Field
              component={TextField}
              label={t("EMAIL")}
              name="email"
              placeholder={t("ENTER_YOUR_EMAIL")}
            />
            <Field
              autoComplete="off"
              component={PasswordField}
              label={t("PASSWORD")}
              name="password"
              placeholder={t("PASSWORD")}
              type="password"
            />
            <Field
              autoComplete="off"
              component={PasswordField}
              label={t("CONFIRM_PASSWORD")}
              name="confirmPassword"
              placeholder={t("REPEAT_PASSWORD")}
              type="password"
            />
            <div className="d-flex flex-column mb-3 align-items-center justify-content-center">
              <Field
                className="mb-1"
                component={CheckboxField}
                label={
                  <>
                    <Text>{t("I_AGREE_WITH")}</Text>{" "}
                    {termsLink ? (
                      <Link target="_blank" to={termsLink}>
                        {t("TERMS_AND_CONDITIONS")}
                      </Link>
                    ) : (
                      t("TERMS_AND_CONDITIONS")
                    )}
                  </>
                }
                name="tcAgreement"
              />

              <ReCAPTCHA
                ref={recaptchaRef}
                hl={currentLang}
                sitekey="6LftckoqAAAAADRGhQZKd1WrEyneigH77W2wMTAR"
                size={"normal"}
                // success
                onChange={(res) => {
                  setCaptchaToken(res);
                }}
              />
            </div>

            <Button block disabled={isLoading || !captchaToken} htmlType="submit" type="primary">
              {t("CONTINUE")}
            </Button>
            <div className="text-center mt-4">
              {t("ALREADY_HAVE_ACCOUNT")}
              &nbsp;
              <Link to="/:theme/login">{loginButtonText ? loginButtonText : t("LOG_IN")}</Link>
            </div>
          </div>
        </Form>
      </AuthLayout>
    </>
  );
};
