import { takeLeading, takeEvery } from "redux-saga/effects";

import { init } from "./init";
import { apiCall } from "./apiCall";

export * from "./fetchApi";
export * from "./authResult";
export * from "./updateSiteConfig";
export * from "./updateCustomerEffect";

/**
 * Root saga - run all effects
 */
export default function* rootSaga(): Generator {
  yield takeLeading("persist/REHYDRATE", init);

  yield takeEvery("CALL_API", apiCall);
}
