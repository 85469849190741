import React, { FC } from "react";

import "./radio.css";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  option: string | number | boolean | null;
  value: string | number | boolean | null;
  onBlur?: () => void;
  disabled?: boolean;
  label?: string;
  name: string;
  id?: string;
}

export const RadioField: FC<Props> = ({ value, name, option, id, label, onChange }) => {
  const htmlId: string = id || `radio-${Math.random()}`;

  return (
    <label className="radio-input" htmlFor={htmlId}>
      <div>
        <input
          checked={option === value}
          id={htmlId}
          name={name}
          type="radio"
          value={`${option || ""}`}
          onChange={onChange}
        />
        <span className="checkmark" />
        {label && <span className="label">{label}</span>}
      </div>
    </label>
  );
};
