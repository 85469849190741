import { Api } from "api";
import { getConfig } from "./helpers";

export type ApiValueInit = { isLoading: false };
export type ApiValueLoading = { isLoading: true };
export type ApiValueSuccess<T> = {
  isLoading: false;
  status: number;
  ok: true;
  data: T;
};
export type ApiValueReload<T> = {
  isLoading: true;
  status: number;
  ok: true;
  data: T;
};
export type ApiValueError = {
  isLoading: false;
  status: number;
  error: Error;
  ok: false;
  data: unknown;
};

/**
 * Api call state
 */
export type ApiValue<T> =
  | ApiValueInit
  | ApiValueLoading
  | ApiValueSuccess<T>
  | ApiValueReload<T>
  | ApiValueError;

/**
 * Supported app locales
 */
type Locale = "en";

export interface AppState {
  api: { [url: string]: undefined | ApiValue<unknown> }; // Local "cache" - Use # to create unique records
  user?: Api["AuthResponse"]; // Session user
  customer?: Api["Customer"]; // logged customer profile
  initialized: boolean; // App is ready to use
  locale: Locale;
  config: Api["SiteWeb"];
  refreshInProgress?: boolean;
  rememberMe?: boolean;
}

/**
 * First load app state
 */
export const initialState: AppState = {
  api: {},
  config: getConfig(),
  initialized: false,
  locale: "en",
};
