import * as Yup from "yup";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const YupConfig: FC = () => {
  const { t } = useTranslation();

  Yup.setLocale({
    mixed: {
      required: () => {
        return t("FIELD_REQUIRED");
      },
    },
    string: {
      email: () => t("ENTER_VALID_EMAIL"),
    },
  });

  return null;
};
