import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { useConfig, useCustomer, useLazyApiCall } from "state";
import { apiServices, isMobile } from "helpers";
import { refreshCustomer } from "state/effects/refreshCustomer";
import { Loader } from "../../components/Loader";

export const LoginWaiver: FC = memo(() => {
  const [display, setDisplay] = useState(true);
  const [loadedFired, setLoadedFired] = useState(false);

  const { pathname } = useLocation();
  const customer = useCustomer();
  const { t } = useTranslation();
  const config = useConfig();
  const siteTheme = config.amsSite?.siteTheme;
  const acceptMsg = siteTheme?.labels?.["label.mealplan.optin.successful"];
  const declineMsg = siteTheme?.labels?.["label.mealplan.optout.successful"];
  const isVE = config.connectorType === "VE";
  const isSkipWaiverAllowed = config.amsSite?.siteWaiver?.canSkip;

  const { dispatch, triggered, isLoading, error, data, ok } = useLazyApiCall(
    apiServices.updateProfile(),
    refreshCustomer
  );

  const waiverVersion = config.amsSite?.siteWaiver.version;
  const wasTriggered = useRef(triggered || 0);

  const handleClose = useCallback(
    (reason: "skip" | "accept" | "decline" | "close") => () => {
      if (isLoading) {
        return;
      }

      if (reason === "close") {
        setDisplay(false);
        return;
      }
      dispatch(
        {
          body: {
            waiverAcceptance:
              reason === "accept" ? "ACCEPT" : reason === "decline" ? "DECLINE" : "SKIP",
            waiverVersion,
          },
        },
        1
      );
      setLoadedFired(false);
    },
    [waiverVersion, isLoading, dispatch]
  );

  useEffect(() => {
    if (pathname.endsWith("/login")) {
      setDisplay(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (document.querySelector("iframe") && !loadedFired) {
      // @ts-ignore the null check is present above
      document.querySelector("iframe").onload = function () {
        // @ts-ignore 'this' refers to the iframe
        const iframeBody = this.contentDocument;
        if (iframeBody === null) setLoadedFired(true);
      };
    }
  });

  useEffect(() => {
    if (isLoading || !triggered || wasTriggered.current === triggered) {
      return;
    }

    wasTriggered.current = triggered;

    if (error) {
      message.error(error);
    }

    if (ok && data) {
      if (String(data.waiverAcceptance).toLowerCase() === "decline") {
        message.success(declineMsg ? declineMsg : t("PAYROLL_DEDUCT_DEACTIVATED"));
      } else if (String(data.waiverAcceptance).toLowerCase() === "accept") {
        message.success(acceptMsg ? acceptMsg : t("PAYROLL_DEDUCT_ACTIVATED"));
      }
    }
    setDisplay(false);
  }, [isLoading, ok, error, data, t, triggered, declineMsg, acceptMsg]);

  const actions = useMemo(
    () => [
      <Button
        disabled={isLoading}
        key="skip"
        size={isMobile() ? "small" : "default"}
        type="secondary"
        onClick={handleClose("skip")}
      >
        {t("CANCEL")}
      </Button>,
      <Button
        disabled={isLoading || !loadedFired}
        key="decline"
        size={isMobile() ? "small" : "default"}
        type="danger"
        onClick={handleClose("decline")}
      >
        {t("DECLINE")}
      </Button>,
      <Button
        disabled={isLoading || !loadedFired}
        key="accept"
        size={isMobile() ? "small" : "default"}
        type="primary"
        onClick={handleClose("accept")}
      >
        {t("ACCEPT")}
      </Button>,
    ],
    [handleClose, isLoading, loadedFired, t]
  );

  let isOpen = display && pathname.endsWith("/wallet-dashboard");

  if (
    (isVE && isSkipWaiverAllowed === true) ||
    !customer || // not logged
    !config.amsSite || // missing site config
    !config.amsSite.enableWaiver || // disabled for this site
    ((customer.waiverAcceptance?.toLowerCase() === "accept" || // user accepted already
      customer.waiverAcceptance?.toLowerCase() === "decline") && // user declined already
      config.amsSite?.siteWaiver.version.toLowerCase() === customer?.waiverVersion?.toLowerCase()) // and version is same
  ) {
    isOpen = false;
  }

  const labels = config.amsSite?.siteTheme?.labels || undefined;

  return !!config.amsSite?.enableWaiver ? (
    <Modal
      waiver
      actions={actions}
      isOpen={isOpen}
      size="large"
      title={(labels && labels["label.mealplan.title.subject"]) ?? t("TERMS_OF_USE")}
      onRequestClose={handleClose("skip")}
    >
      <Loader size={"large"} spinning={!loadedFired && isLoading}>
        <iframe
          src={isOpen ? config.amsSite?.siteWaiver?.waiverUrl : ""}
          // eslint-disable-next-line no-inline-styles/no-inline-styles
          style={{ border: "none", height: "100%", width: "100%" }}
          title="DGPR"
        />
      </Loader>
    </Modal>
  ) : null;
});
