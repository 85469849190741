import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { createStore, applyMiddleware, Store, compose } from "redux";
import { persistStore, persistReducer, Persistor } from "redux-persist";

import { initialState } from "state/AppState";
import { AppState, reducer, Action } from "state"; // TODO: use persisted state maybe ?

import rootSaga from "../effects";

const composeEnhancers = compose;

export function createAppStore(
  customInitial?: Partial<AppState>
): {
  store: Store<AppState, Action>;
  persistor: Persistor;
} {
  const persistConfig = {
    key: "root",
    storage,
    whitelist: ["user", "customer", "rememberMe"],
  };

  const persistedReducer = persistReducer(persistConfig, reducer);

  const sagaMiddleware = createSagaMiddleware();
  const store = createStore<AppState, Action, unknown, unknown>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: improve create store ts
    persistedReducer,
    { ...initialState, ...(customInitial || {}) },
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);

  // @ts-ignore SKIP
  const persistor = persistStore(store);
  return { persistor, store };
}
