import React, { FC, useCallback } from "react";

import { Api } from "api";
import { useApiCall } from "state";
import { apiServices } from "helpers";
import { useTranslation } from "react-i18next";

import { PayConex } from "./PayConex";
import { PayconexData } from "./PaymentiFrame";
import { FreedomPay, FreedomResponse } from "./FreedomPay";

export type PaymentResponse =
  | {
      type: "PAY_CONEX";
      data: PayconexData;
    }
  | { type: "FREEDOM_PAY"; data: FreedomResponse };

interface Props {
  onSuccess?: (res: PaymentResponse) => void;
}

/**
 * Interface for payments
 */
export const Payment: FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const { dispatch, data, ok, isLoading, error } = useApiCall<Api["SitePaymentIFrame"]>(
    apiServices.paymentIframe()
  );
  const refresh = useCallback(() => dispatch(undefined, -1), [dispatch]);

  if (isLoading) {
    return <div className="p-5 text-center w-100">{t("LOADING")}</div>;
  }

  if (!ok || !data || error) {
    return <div className="p-5 text-center w-100">{t("ERROR_LOADING_PAYMENT_PROVIDER")}</div>;
  }

  // TODO: handle refresh iframe url for Freedompay

  return data && data.type && data.type === "PAY_CONEX" ? (
    <PayConex
      url={data.url || ""}
      onSuccess={(data: PayconexData) =>
        onSuccess &&
        onSuccess({
          data,
          type: "PAY_CONEX",
        })
      }
    />
  ) : (
    <FreedomPay
      url={data.url || ""}
      onRefresh={refresh}
      onSuccess={(data: FreedomResponse) => onSuccess && onSuccess({ data, type: "FREEDOM_PAY" })}
    />
  );

  return null;
};
