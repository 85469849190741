import React, { FC, useCallback } from "react";
import { CarouselInternalState } from "react-multi-carousel";
import { Pagination as AntdPagination } from "antd";

import { RenderDesktop, RenderMobile } from "components/RenderMatchMedia";

import "./pagination.css";

interface Props {
  carouselState?: CarouselInternalState;
  goToSlide?: (val: number) => void;
}

export const Pagination: FC<Props> = ({ goToSlide, carouselState }) => {
  const { totalItems, slidesToShow, currentSlide } = carouselState || {
    slidesToShow: 3,
    totalItems: 3,
  };

  const go = useCallback(
    (page: number) => {
      goToSlide && goToSlide((page - 1) * slidesToShow);
    },
    [goToSlide, slidesToShow]
  );

  if (slidesToShow >= totalItems) {
    return null;
  }

  return (
    <>
      <RenderDesktop>
        <div className="d-flex justify-content-end w-100">
          <AntdPagination
            simple
            defaultCurrent={1}
            pageSize={slidesToShow}
            total={totalItems}
            onChange={go}
          />
        </div>
      </RenderDesktop>
      <RenderMobile>
        <div className="mobile-carousel-pagination">
          {new Array(totalItems).fill(Boolean).map((_i, k) => (
            <a
              data-active={currentSlide === k}
              href={`#slide-${k}`}
              key={k}
              onClick={(e) => {
                e.preventDefault();
                goToSlide && goToSlide(k);
              }}
            >
              <span>{k}</span>
            </a>
          ))}
        </div>
      </RenderMobile>
    </>
  );
};
