import cn from "classnames";
import React, { FC } from "react";

import styles from "./avatar.module.css";

interface Props {
  size?: "small" | "medium" | "large";
  className?: string;
  icon: string;
}

export const Avatar: FC<Props> = ({ icon, className, size = "medium" }) => {
  const parts = (icon || " ").trim().split(" ").filter(Boolean);

  const letter =
    size === "large"
      ? // in case of large we want to transform "Tom Hanks -> TH"
        parts.length > 1
        ? parts
            .map((i) => i.trim().substr(0, 1)) // use 1st letter only
            .slice(0, 2) // select only first 2
            .join("")
        : // use first two letters from first (only) word "Tom" -> "TO"
          (parts[0] && parts[0].substr(0, 2)) || ""
      : // in case of small / normal we just going to use firts letter -> "Tom Hanks -> T"
        (parts[0] || "").substr(0, 1);

  return (
    <div className={cn(styles.avatar, styles[size], className)} data-testid="avatar">
      {letter.toUpperCase()}
    </div>
  );
};
