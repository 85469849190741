import { Api } from "api";

// TODO: improve once api will be ready
export type Account = Api["Account"] & {
  shared?: { name: string }[];
  hasMealPlan?: boolean;
  maxAmount?: number;
  mealPlan?: number;
  name: string;
  newLoadAccountAllowed?: boolean;
  // TODO: fix once accounts will be fetched from api
  balance: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  autoloadDetails?: any;
};

export const accounts: Account[] = [
  // hide for marketing
  // {
  //   accountId: "19000",
  //   balance: 10,
  //   maxAmount: 100,
  //   name: "Meal Dollars",
  //   newLoadAccountAllowed: true,
  //   shared: [{ name: "Karen Mach" }, { name: "Sheril Cheng" }],
  // },
  // {
  //   accountId: "2000100",
  //   balance: 20.8,
  //   name: "Room Charge",
  //   newLoadAccountAllowed: true,
  // },
  // {
  //   accountId: "2000101",
  //   balance: 12,
  //   hasMealPlan: true,
  //   mealPlan: 13,
  //   name: "Meal Credits",
  // },
  {
    accountId: "2000102",
    balance: 10,
    name: "Payroll Deduction",
  },
  {
    accountId: "2000103",
    autoloadDetails: {
      loadAmount: 50,
      subscriptionStatus: true,
      thresholdAmount: 10,
    },
    balance: 34.2,
    maxAmount: 100,
    name: "Meal Dollars",
    newLoadAccountAllowed: true,
  },
];
