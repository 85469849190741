import React, { FC, HTMLAttributes } from "react";
import cn from "classnames";

export const Container: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={cn("container", className)} {...props}>
      {children}
    </div>
  );
};
