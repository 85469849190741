import cn from "classnames";
import React, { FC } from "react";

import { formatPrice } from "helpers/formatPrice";

import styles from "./print-price.module.css";

export const PrintPrice: FC<{ size?: "large"; value: string | number }> = ({ value, size }) => (
  <span
    className={cn(Number(value) >= 0 ? styles.greenText : "", {
      [styles.largeText]: size === "large",
    })}
  >
    {(Number(value) >= 0 ? "+" : "") + formatPrice(value)}
  </span>
);
