/**
 * Invoke captcha check
 */
export function getCaptchaToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY || "", { action: "submit" })
        .then(resolve, reject);
    });
  });
}
