import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createBrowserHistory, History } from "history";

const routeHistory: History = createBrowserHistory();

let lastPath = window.location.pathname;

type AppHistory = History & { pushWithTheme: (path: string) => void };

const history = {
  ...routeHistory,
  pushWithTheme: function pushWithTheme(path: string) {
    const theme =
      `/${routeHistory.location.pathname}`.replace(/^\/+/, "").split("/")[0] || "default";
    const target = `/${theme}${path}`;
    routeHistory.push(target);
  },
};

export const getHistory = (): AppHistory => history;

export const WatchPath: FC = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    lastPath = pathname;
  }, [pathname]);
  return null;
};

export function getLastPath(): string {
  return lastPath;
}
