import { Api } from "api";
import { put, select } from "redux-saga/effects";
import { updateCustomer as updateCustomerAction } from "state/actions";

import { ApiValueError, ApiValueSuccess } from "state/AppState";

export function* updateCustomersNotificationsEffect(
  update: ApiValueError | ApiValueSuccess<Api["Notification"]>
): unknown {
  if (update.ok && update.data) {
    const customer = yield select((s) => s.customer);
    if (!customer) {
      return;
    }
    yield put(updateCustomerAction({ ...customer, notification: update.data }));
  }
}
