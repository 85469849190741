import { Api } from "api";

export const login = (): Api["AuthResponse"] => ({
  accessToken: "authorized",
  accessTokenExpiry: new Date(Date.now() + 1 * 60 * 60 * 1000).toISOString(),
  profile: {
    id: "1",
    name: "Tom Hanks",
  },
  refreshToken: "refresh",
  refreshTokenExpiry: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
});
