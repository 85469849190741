import { Api } from "api";
import { apiServices } from "helpers";
import { put, select, take } from "redux-saga/effects";

import { AppState } from "state";
import { refreshTokenDone, refreshTokenStart, updateUser } from "state/actions";

import { fetchApi } from "./fetchApi";

export function* refreshToken(token: string): unknown {
  const running = yield select((s: AppState) => s.refreshInProgress);

  // check if request was triggered already
  if (running) {
    yield take("REFRESH_TOKEN_DONE");
  } else {
    // start refresh process
    yield put(refreshTokenStart());
    try {
      const refreshedSession = yield fetchApi<Api["AuthResponse"]>(apiServices.refreshToken(token));

      if (refreshedSession.ok && refreshedSession.data) {
        const customer = yield select((s: AppState) => s.customer);

        yield put(updateUser(refreshedSession.data, customer));
      }
    } finally {
      yield put(refreshTokenDone());
    }
  }
}
