import { Radio } from "antd";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Route, Redirect, Switch, useRouteMatch, useLocation } from "react-router-dom";

import { Card } from "components/Card";
import { getHistory } from "helpers/history";
import { Title } from "components/Typography";

import { Payment } from "./sections/Payment";
import { PersonalInfo } from "./sections/PersonalInfo";
import { Notifications } from "./sections/Notifications";

export const Profile: FC = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const handleTabChange = useCallback(
    (val) => {
      getHistory().replace(`${url}/${val.target.value}`);
    },
    [url]
  );

  const activeSection = pathname.replace(url, "").replace(/^\//, "");
  const sections = ["personal-info", "notifications", "payment"];
  const defaultSection = sections.includes(activeSection) ? activeSection : sections[0];

  return (
    <>
      {" "}
      <Title level={1}>{t("MY_PROFILE")}</Title>
      <Card className="mb-4" padding="none" radius="none">
        <Radio.Group
          buttonStyle="solid"
          className="mobile-expand"
          optionType="button"
          value={defaultSection}
          onChange={handleTabChange}
        >
          <Radio.Button value={sections[0]}>{t("PERSONAL_INFO")}</Radio.Button>
          <Radio.Button value={sections[1]}>{t("NOTIFICATION")}</Radio.Button>
          <Radio.Button value={sections[2]}>{t("PAYMENT")}</Radio.Button>
        </Radio.Group>
      </Card>
      {/* TODO: use hash router for tabs... */}
      <Switch>
        <Route component={PersonalInfo} path={`${url}/personal-info`} />
        <Route component={Notifications} path={`${url}/notifications`} />
        <Route component={Payment} path={`${url}/payment`} />

        <Redirect to={`${url}/personal-info`} />
      </Switch>
    </>
  );
};
