import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Api } from "api";
import { Avatar } from "components/Avatar";
import { Text } from "components/Typography";
import { formatAccount, userFullName } from "helpers";

import styles from "./personalCard.module.css";

interface Props {
  user?: Api["Customer"];
  accountTitleMsgLabel: string;
}

export const PersonalCard: FC<Props> = ({ user, accountTitleMsgLabel }) => {
  const { t } = useTranslation();

  const content = useMemo(
    () =>
      user ? (
        <>
          <div>
            <Avatar icon={userFullName(user)} size="large" />
          </div>
          <div className={styles.name}>
            <Text size="large">{userFullName(user)}</Text>
            <Text className="mt-2" color="disabled" size="normal">
              {accountTitleMsgLabel}: {formatAccount(user.customerCode ?? "")}
            </Text>
          </div>
        </>
      ) : (
        <div className="p-4">{t("MISSING_USER_PROFILE")}</div>
      ),
    [t, user, accountTitleMsgLabel]
  );

  return <div className={styles.personalCard}>{content}</div>;
};
