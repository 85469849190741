import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useBarcode } from "react-barcodes";
import { isMobile } from "helpers";

import { Modal } from "components/Modal";
import "./Barcode.css";

import square from "assets/images/barcode_square.svg";
import circle from "assets/images/barcode_circle.svg";

interface Props {
  onRequestClose?: () => void;
  employeeId?: string;
  name?: string;
  barcode?: string;
  isOpen: boolean;
}

export const BadgeBarcode: FC<Props> = ({ employeeId, name, barcode, isOpen, onRequestClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      actions={null}
      isOpen={isOpen}
      maxHeight={false}
      size="normal"
      title={t("BADGE")}
      onRequestClose={onRequestClose}
    >
      <div style={{ minHeight: isMobile() ? 600 : 300 }}>
        <div className={isMobile() ? "rectangle-box-mobile" : "rectangle-box"}>
          <img alt="" className="square" src={square} />
          <div>
            <span className="employee-id">{t("Employee ID: {employeeId}", { employeeId })}</span>
            <br />
            <span className="name">{name}</span>{" "}
          </div>
          <img alt="" className="circle" src={circle} />
          <Barcode code={barcode}></Barcode>
        </div>
      </div>
    </Modal>
  );
};

function Barcode(props: { code?: string }) {
  const { inputRef } = useBarcode({
    options: {
      background: "#FFFFFF",
    },
    value: props.code || "",
  });
  return <svg ref={inputRef} className="rectangle-barcode" />;
}
