import React, { FC } from "react";

import visa from "assets/images/card-icon/visa.svg";
import general from "assets/images/card-icon/general.svg";
import mastercard from "assets/images/card-icon/mastercard.svg";

const map = { general, mastercard, visa } as const;

export const CreditCardIcon: FC<{
  className?: string;
  type: keyof typeof map | string | undefined;
}> = ({ type, ...props }) => {
  const getMap = map as { [key: string]: string };
  return (
    <img {...props} alt={`${type} card icon`} src={(type && getMap[type]) || getMap.general} />
  );
};
