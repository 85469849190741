import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "components/Icon";
import { Button } from "components/Button";
import { getHistory } from "helpers/history";
import { Auth as AuthLayout } from "layouts";
import { Text, Title } from "components/Typography";

export const ResetSuccess: FC = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <div className="text-center d-flex flex-column align-items-center ">
        <Text color="success">
          <Icon name="check-circle" size={56} />
        </Text>
        <Title className="mb-5 mt-4" level={1}>
          {t("PASSWORD_RESET_SUCCESS")}
        </Title>
        <Text>{t("NOW_GET_BACK_INTO_ACCOUNT")}</Text>
        <Button
          className="w-50 mt-5"
          type="primary"
          onClick={() => getHistory().pushWithTheme("/login")}
        >
          {t("GO_TO_LOGIN")}
        </Button>
      </div>
    </AuthLayout>
  );
};
