import React, { FC } from "react";

import { useIsDesktop } from "helpers/isMobile";

export const RenderDesktop: FC = ({ children }) => {
  const isDesktop = useIsDesktop();

  if (isDesktop) {
    return <>{children}</>;
  }

  return null;
};
