import cn from "classnames";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { Button } from "components/Button";
import { Text } from "components/Typography";
import { getLocale, stringify } from "helpers";

import styles from "./payconnex.module.css";
import { EncryptRes, Settings, PayconexInvalidInput, PayconexData } from "./PaymentiFrame";
interface Props {
  onSuccess?: (res: PayconexData) => void;
  url?: string;
}

const lib = "https://secure.payconex.net/iframe/iframe-lib-1.0.0.js";
const libTagId = "payconex-lib";

const AID = process.env.REACT_APP_OVERRIDE_PAYCONEX;

// https://www.bluefin.com/wp-content/uploads/2017/03/Bluefin-Payment-iFrame-API-0317.pdf
const src = `https://cert.payconex.net/iframe?${stringify({
  aid: AID,
  cvv: "requird",
  cvv_label: "9",
  expy: "single_input",
  expy_label: "3",
  input_style: "1",
  input_width: "50%",
  label_font_family: "4",
  label_font_size: "16",
  label_width: "40%",
  lang: getLocale().substr(0, 2),
  layout: "3",
  number_label: "3",
  show_placehoders: "true",
})}`;

declare class PaymentiFrame {
  clear: () => void;
  encrypt: () => EncryptRes;
  setMasked: (maskedData: unknown, eToken: string, settings: Settings) => void;
  constructor(options: { [key: string]: string | number | undefined | boolean });
}

export const PayConex: FC<Props> = ({ url, onSuccess }) => {
  const { t } = useTranslation();
  const [ready, setReady] = useState(!!document.getElementById(libTagId));
  const [validations, setValidations] = useState<PayconexInvalidInput[]>([]);

  const paymentiFrame = useRef<PaymentiFrame>();
  useEffect(() => {
    const loaded = document.getElementById(libTagId);
    if (loaded) {
      return;
    }
    const script = document.createElement("script");

    script.src = lib;
    script.async = true;
    script.id = libTagId;
    script.onload = () => setReady(true);

    document.body.appendChild(script);

    return () => {
      script.onload = () => {
        // prevent set-state on unmounted error
      };
    };
  }, []);

  useEffect(() => {
    if (ready) {
      paymentiFrame.current = new PaymentiFrame({
        create: false,
        iframeId: "payment_iframe",
      });

      paymentiFrame.current.clear();
    }
  }, [ready]);

  const handleEncrypt = () => {
    if (!paymentiFrame.current) {
      return;
    }

    setValidations([]);

    paymentiFrame.current
      .encrypt()
      .failure((err) => {
        alert(`Error: ${err.id} -> ${err.message}`);
      })
      .invalidInput((data) => {
        setValidations(data.invalidInputs);
      })
      .success((res) => {
        onSuccess && onSuccess(res);
        paymentiFrame.current?.clear();
      });

    return false;
  };

  const errors = useMemo(
    () => ({
      1000: (field: string) => t("VALUE_OF_FIELD_REQUIRED", { field }),
      1001: (field: string) => t("VALUE_OF_FIELD_TOO_SHORT", { field }),
      1002: (field: string) => t("VALUE_OF_FIELD_TOO_LONG", { field }),
      1003: (field: string) => t("VALUE_OF_FIELD_MUST_BE_NUMBER", { field }),
      1004: (field: string) => t("INVALID_CARD_NUMBER", { field }),
      1005: (field: string) => t("INVALID_EXPIRY_DATE", { field }),
    }),
    [t]
  );

  if (!ready) {
    return <div className="p-5 text-center">{t("LOADING")}</div>;
  }

  return (
    <>
      <iframe
        height="200"
        id="payment_iframe"
        scrolling="no"
        src={process.env.REACT_APP_OVERRIDE_PAYCONEX ? src : url}
        // eslint-disable-next-line no-inline-styles/no-inline-styles
        style={{ border: "none" }}
        title="pay"
        width="100%"
      />
      {validations && validations.length > 0 && (
        <div>
          {validations.map((it) => (
            <Text className="d-block" color="error" key={it.field}>
              {errors[it.code](it.field)}
            </Text>
          ))}
        </div>
      )}
      <Button
        block
        className={cn("mt-4", styles.saveCardButton)}
        type="primary"
        onClick={handleEncrypt}
      >
        {t("SAVE")}
      </Button>
    </>
  );
};
