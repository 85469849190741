import { select, put } from "redux-saga/effects";

import { logger } from "helpers";

import { fetchApi } from "./fetchApi";
import { ApiValue } from "../AppState";
import { Actions, AppState } from "../";
import { refreshToken } from "./refreshToken";
import { apiCallStart, resolveApiCall } from "../actions";

const TTL_BUMPER = 30000; // trigger refresh token before its invalidated

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* apiCall<T = Record<string, any>>({
  options,
  finalEffect,
  force,
}: Actions["callApi"]): unknown {
  const prev: undefined | ApiValue<T> = yield select((s: AppState) => s.api[options.path]);

  /**
   * Skip same request if we already have some data
   */
  if (force === undefined && prev && "ok" in prev && prev.ok) {
    return;
  }

  /**
   * !undefined = if enabled, repeat request
   * 1 = switch to "not loaded" state and trigger new request
   * -1 = until its resolved, we keep prev state
   */
  if (force !== undefined || !prev) {
    yield put(apiCallStart(options.path, force === -1));
  }

  if (options.auth !== false) {
    const ses: AppState["user"] = yield select((s: AppState) => s.user);
    if (ses) {
      try {
        if (
          ses.accessToken &&
          ses.accessTokenExpiry &&
          new Date(ses.accessTokenExpiry) < new Date(Date.now() + TTL_BUMPER) &&
          ses.refreshToken &&
          ses.refreshTokenExpiry &&
          new Date(ses.refreshTokenExpiry) > new Date()
        ) {
          yield refreshToken(ses.refreshToken);
        }
      } catch (e) {
        logger.error(new Error(`Error while working on refresh token flow: ${e.message}`));
      }
    }
  }

  const response = yield fetchApi<T>(options);

  if (finalEffect && typeof finalEffect === "function") {
    yield finalEffect(response);
  }

  yield put(resolveApiCall(options.path, response));
}
