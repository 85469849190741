import { Api } from "api";
import { apiServices } from "helpers";
import { put, select } from "redux-saga/effects";

import { callApi, updateUser } from "state/actions";
import { ApiValueError, ApiValueSuccess } from "state/AppState";

export function* refreshCustomer(): unknown {
  yield put(callApi(apiServices.customer(), onCustomerDone, -1));
}

export function* onCustomerDone(res: ApiValueError | ApiValueSuccess<Api["Customer"]>): unknown {
  if (res && res.ok) {
    const user = yield select((s) => s.user);
    const customer = res.data;

    yield put(updateUser(user, customer));
  }
}
