import { Col } from "antd";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Account } from "api";
import { useCustomer } from "state";
import { Row } from "components/Grid";
import { userFullName } from "helpers";
import { getHistory } from "helpers/history";
import { Title } from "components/Typography";

import { AccountsList } from "./components/AccountsList";
import { TransactionsContainer } from "./containers/TransactionsContainer";
import { Button } from "../../components/Button";
import "./Barcode.css";
import { BadgeBarcode } from "./BadgeBarcode";
import circle from "../../assets/images/badge-button.svg";

export const WalletDashboard: FC = () => {
  const { t } = useTranslation();
  const handleReload = (acc: Account) => {
    getHistory().pushWithTheme(`/reload/manual/${acc.accountId}`);
  };

  // commenting out to fix later TODO: @YK
  // replaced it with a fetch after transactions load in TransactionsContainer.tsx
  // const { dispatch } = useLazyApiCall(apiServices.customer(), onCustomerDone);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const customer = useCustomer()!;
  const accounts = (customer.accounts || []) as Account[];
  const username = userFullName(customer);

  const [open, setOpen] = useState(false);

  return (
    <>
      {/* TODO: use account  */}
      <Title level={1}>{t("WELCOME_USER", { username })}</Title>

      {customer.badgeCode && (
        <Row className="w-100 mb-3 mt-1">
          <Col
            lg={{ offset: 0, span: 5 }}
            md={{ offset: 0, span: 10 }}
            xs={{ offset: 0, span: 24 }}
          >
            <Button block type="primary" onClick={() => setOpen(true)}>
              <img alt="" className="badge-icon" src={circle} />
              <span className="badge-text">{t("VIEW_BADGE")}</span>
            </Button>
          </Col>
        </Row>
      )}

      {customer.badgeCode && <br />}

      <Title className="mb-3" level={5}>
        {t("ACCOUNTS")}
      </Title>

      <AccountsList accounts={accounts} onReload={handleReload} />

      <Row>
        <Col span={24}>
          <Title className="mb-3" level={5}>
            {t("RECENT_TRANSACTIONS")}
          </Title>
        </Col>
      </Row>

      <TransactionsContainer />

      <BadgeBarcode
        barcode={customer.badgeCode}
        employeeId={customer.customerId}
        isOpen={open}
        name={username}
        onRequestClose={() => setOpen(false)}
      />
    </>
  );
};
