import { App } from "App";
import React from "react";
import ReactDOM from "react-dom";

import "helpers/i18next";

import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  // <React.StrictMode> // have to be disabled bcs of antd errors ...
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
