import { message } from "antd";
import React, { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Api } from "api";
import { apiServices } from "helpers";
import { useLazyApiCall } from "state";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { Text } from "components/Typography";
import { CreditCardIcon } from "components/Icon";
import { maskCardNumber } from "helpers/maskCardNumber";
import { refreshCustomer } from "state/effects/refreshCustomer";

interface Props {
  onRequestClose?: (manual?: boolean) => void;
  isOpen: boolean;
  target?: Api["SavedPayment"];
}

export const RemovePaymentModal: FC<Props> = ({ target, isOpen, onRequestClose }) => {
  const { dispatch, ok, isLoading, error } = useLazyApiCall(
    apiServices.removePayment(),
    refreshCustomer
  );
  const onClose = useRef(onRequestClose);

  const { t } = useTranslation();
  const handleRemove = () => {
    if (!target) {
      return;
    }
    dispatch(apiServices.removePayment(target.id), 1);
  };

  useEffect(() => {
    onClose.current = onRequestClose;
  }, [onRequestClose]);

  useEffect(() => {
    if (error) {
      message.error(t("PAYMENT_METHOD_NOT_REMOVED"));
    }
  }, [error, t]);

  useEffect(() => {
    if (ok) {
      onClose.current && onClose.current();
      message.success(t("PAYMENT_METHOD_REMOVED"));
    }
  }, [ok, t]);

  return (
    <Modal
      actions={[
        <Button key="cancel" type="secondary" onClick={onRequestClose}>
          {t("CANCEL")}
        </Button>,
        <Button disabled={isLoading} key="submit" type="primary" onClick={handleRemove}>
          {t("REMOVE")}
        </Button>,
      ]}
      isOpen={isOpen}
      title={t("REMOVE_PAYMENT_METHOD")}
      onRequestClose={() => onRequestClose?.(true)}
    >
      <div className="text-center">
        <Text>{t("CONF_REMOVE_PAYMENT_METHOD")}</Text>
        {target && (
          <Text className="d-block text-center p-4" size="medium">
            <CreditCardIcon className="mr-2" type={target?.creditCardType} />
            {maskCardNumber(target?.creditCardLast4)}
          </Text>
        )}
      </div>
    </Modal>
  );
};
