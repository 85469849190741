import { Col, message } from "antd";
import * as Yup from "yup";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Row } from "components/Grid";
import { apiServices } from "helpers";
import { Button } from "components/Button";
import { Field, Form } from "components/Form";
import { passwordValidator } from "helpers/yup";
import { PasswordField } from "components/Form/fields/PasswordField";
import { updateCustomerEffect, useLazyApiCall } from "state";

interface Props {
  onSuccess: () => void;
  onCancel: () => void;
}

export const PasswordForm: FC<Props> = ({ onCancel, onSuccess }) => {
  const { dispatch, isLoading, ok, error, triggered } = useLazyApiCall(
    apiServices.setPassword(),
    updateCustomerEffect
  );
  const lastTriggered = useRef(triggered || 0);
  const { t } = useTranslation();
  const schema = Yup.object()
    .shape({
      newPassword: Yup.string()
        .test(passwordValidator as never)
        .nullable()
        .required()
        .default(null),
      originalPassword: Yup.string()
        .test(passwordValidator as never)
        .nullable()
        .required()
        .default(null),
    })
    .defined();

  type FormData = Yup.InferType<typeof schema>;

  const initialValues = schema.cast();

  const handleSubmit = useCallback(
    (body: FormData) => {
      if (isLoading) {
        return;
      }
      dispatch({ body }, 1);
    },
    [dispatch, isLoading]
  );

  useEffect(() => {
    if (ok && triggered && lastTriggered.current !== triggered) {
      lastTriggered.current = triggered;
      onSuccess();
      message.success(t("PASSWORD_CHANGED_SUCCESSFULLY"));
    }
  }, [ok, onSuccess, triggered, t]);

  useEffect(() => {
    error && message.error(error);
  }, [error]);

  return (
    <Form<FormData>
      id="passwordForm"
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Field
            autoComplete="off"
            component={PasswordField}
            label={t("CURRENT_PASSWORD")}
            name="originalPassword"
          />
        </Col>
        <Col span={24}>
          <Field
            autoComplete="off"
            component={PasswordField}
            label={t("NEW_PASSWORD")}
            name="newPassword"
          />
        </Col>
        <Col md={8} xs={12}>
          <Button block type="outline" onClick={onCancel}>
            {t("CANCEL")}
          </Button>
        </Col>
        <Col md={8} xs={12}>
          <Button block disabled={isLoading} htmlType="submit" type="primary">
            {t("SAVE")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
