/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from "react";

import { callApi, clearApiCall } from "../actions";
import { useDispatch, useSelector, RequestOptions, useConfig } from "..";
import { ApiValue, ApiValueError, ApiValueSuccess } from "../AppState";

type BaseApiState<C> = {
  triggered: C;
  /**
   * Start Request by action
   *
   * Request options overrides
   * - customize options for each Request,
   * - body and headers will be merged with initial value so if you need delete something use undefined
   * @param overrides
   *
   * Force re-fetch request
   * 1 = refetch even if we have some data already = clear data and fetch new
   * -1 = refetch but until we get response, keep old data
   *
   * @param {1 | -1} force
   */
  dispatch: (overrides?: Partial<RequestOptions>, force?: 1 | -1) => void;
};

type InitialState = BaseApiState<undefined> & {
  isLoading: false;
  status: undefined;
  error: undefined;
  data: undefined;
  ok: undefined;
};

type LoadingState = BaseApiState<number> & {
  status: undefined;
  error: undefined;
  data: undefined;
  isLoading: true;
  ok: undefined;
};

type SuccessState<T, L = false> = BaseApiState<number> & {
  error: undefined;
  status: number;
  isLoading: L;
  ok: true;
  data: T;
};

type ErrorState = BaseApiState<number> & {
  isLoading: false;
  status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
  error: Error;
  ok: false;
};

type ReloadState<T> = SuccessState<T, true>;

type ApiState<T> = InitialState | LoadingState | SuccessState<T> | ErrorState | ReloadState<T>;

const watchers: { [path: string]: Set<symbol> } = {};

export function useLazyApiCall<T = Record<string, any>>(
  /**
   * Same as Fetch options
   * - but for query string use "query" prop
   *   (feel free to put JSON which will be serialized automatically)
   */
  options: RequestOptions,
  /**
   * Trigger custom effect on request done event (success or not)
   */
  finalEffect?: (response: ApiValueError | ApiValueSuccess<any>) => void
): ApiState<T> {
  const { method } = options;
  const [path, setPath] = useState(options.path);
  const res = useSelector<ApiValue<T> | undefined>((s) => s.api[path] as ApiValue<T>);
  const triggered = useRef(0);
  const d = useDispatch();
  const config = useConfig();

  const dispatch = useCallback(
    (overrides: Partial<RequestOptions> = {}, force?: 1 | -1) => {
      triggered.current++;
      const o = {
        ...(options || {}),
        headers: {
          ...((options || {}).headers || {}),
          ...(overrides.headers || {}),
        },
        ...overrides,
        body: {
          ...(options.body || {}),
          ...(overrides.body || {}),
        },
        isVE: config.connectorType === "VE",
        method,
      };

      setPath(o.path);
      // @ts-ignore TODO: improve typings
      d(callApi(o, finalEffect, force));
    },
    // TODO: use deep-equal comparator
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, method, d, finalEffect]
  );

  // clear storage at moment when no-one is watching
  useEffect(() => {
    const unsubscribe = watch(path);
    return () => {
      unsubscribe();
      !hasWatchers(path) && d(clearApiCall(path));
    };
  }, [d, path]);

  if (!res || !("ok" in res)) {
    return { dispatch, isLoading: !!res?.isLoading, triggered: undefined } as InitialState;
  } else if ("ok" in res && res.ok) {
    return res.isLoading
      ? ({ ...res, dispatch, triggered: triggered.current } as ReloadState<T>)
      : ({ ...res, dispatch, triggered: triggered.current } as SuccessState<T>);
  } else if (res.ok === false) {
    return { ...res, dispatch, triggered: triggered.current } as ErrorState;
  }

  return { dispatch, isLoading: true, triggered: triggered.current } as LoadingState;
}

export function useApiCall<T = Record<string, any>>(
  /**
   * Same as Fetch options
   * - but for query string use "query" prop
   *   (feel free to put JSON which will be serialized automatically)
   */
  options: RequestOptions,
  /**
   * Trigger custom effect on request done event (success or not)
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  finalEffect?: (response: ApiValueError | ApiValueSuccess<any>) => void
): ApiState<T> {
  const state = useLazyApiCall<T>(options, finalEffect);

  const triggerOnce = useRef(state.status ? undefined : state.dispatch);

  useEffect(() => {
    triggerOnce.current && triggerOnce.current();
  }, []);

  // @ts-ignore TODO: ...
  return {
    ...state,
    isLoading: state.status ? state.isLoading : !state.ok,
    triggered: state.triggered || 1,
  };
}

/** register watcher */
function watch(path: string): () => void {
  watchers[path] = watchers[path] || new Set();
  const uid = Symbol(path);
  watchers[path].add(uid);

  return () => {
    watchers[path].delete(uid);
  };
}

/** check if no-one listening data */
function hasWatchers(path: string): boolean {
  return watchers[path]?.size > 0;
}
