import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useRouteMatch } from "react-router-dom";

import { updateUser } from "state/actions";
import { Title } from "components/Typography";
import { useDispatch, useLoggedIn } from "state";

export const Logout: FC = () => {
  const {
    params: { theme },
  } = useRouteMatch<{ theme: string }>();
  const isLoggedIn = useLoggedIn();
  const { t } = useTranslation();
  const d = useDispatch();

  useEffect(() => {
    d(updateUser());
  });

  if (!isLoggedIn) {
    return <Redirect to={`${theme}/login`} />;
  }

  return (
    <Title className="mb-5" level={1}>
      {t("LOGGING_OUT")}
    </Title>
  );
};
