import i18n from "i18next";
import xhr from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import ICU from "i18next-icu";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(xhr)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(ICU)
  .init({
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
    },
    defaultNS: "default",
    detection: {
      caches: ["localStorage", "cookie"], // cache user language on
      order: [
        "localStorage",
        "navigator",
        "querystring",
        "cookie",
        "sessionStorage",
        "htmlTag",
        "path",
        "subdomain",
      ],
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    keySeparator: ".",
    ns: ["default"],
    nsSeparator: "::",

    react: {
      defaultTransParent: "div", // a valid react element - required before react 16
      transEmptyNodeValue: "", // what to return for empty Trans
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    },
  })
  .then();

export default i18n;
