import { ApiValueError, ApiValueSuccess } from "state/AppState";
import { RequestOptions } from "../effects/fetchApi";

/**
 *  Trigger Api Call processing
 * @param path api route path - state identifier
 * @param options request options
 */
export const callApi = (
  options: RequestOptions,
  /**
   * if we are done, run another action as callback
   * - we are able to use this generic function with custom post-processing
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  finalEffect?: (response: ApiValueError | ApiValueSuccess<any>) => void,
  /**
   * Force re-fetch request
   * 1 = refetch even we have some data already
   * -1 = refetch but until we get response, keep old data
   */
  force?: 1 | -1
) => ({ finalEffect, force, options, type: "CALL_API" } as const);
