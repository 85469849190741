import { Api } from "api";
import { put, select } from "redux-saga/effects";

import { AppState } from "state";
import { initialized as initDoneAction } from "state/actions";
import { ApiValueError, ApiValueSuccess } from "state/AppState";

export function* updateSiteConfig(res: ApiValueError | ApiValueSuccess<Api["SiteWeb"]>): unknown {
  if (res && res.ok) {
    const config = (yield select((s) => s.config)) as AppState["config"];
    // @ts-ignore TODO:
    const conf = mergeConfig(config, res.data);

    yield put(initDoneAction(conf));
  }
}
