import { Dispatch } from "react";
import { createSelectorHook, useDispatch as useReduxDispatch } from "react-redux";

// action reservoir
import * as actions from "./actions";
// state definition
import type { AppState } from "./AppState";
// re-export
export type { AppState };

/**
 * Union of action creators names
 */
export type ActionNames = keyof typeof actions;
/**
 * Union of Action types
 */
export type Action = ReturnType<typeof actions[ActionNames]>;
/**
 * All Reducer Actions Union
 */
export type ActionType = Action["type"];

/**
 * App actions Union
 */
export type Actions = { [K in ActionNames]: ReturnType<typeof actions[K]> };

/**
 * App specific / typed useSelector hook
 */
export const useSelector = createSelectorHook<AppState>();

/**
 * App selector for user
 */
export const useUser = (): AppState["user"] => useSelector(({ user }: AppState) => user);

/**
 * App selector for customer
 */
export const useCustomer = (): AppState["customer"] =>
  useSelector(({ customer }: AppState) => customer);

/**
 * App specific / typed useDispatch
 */
export const useDispatch: () => Dispatch<Action> = useReduxDispatch;

export * from "./reducer";
export * from "./effects";
export * from "./helpers";
export * from "./components";

export { actions };
