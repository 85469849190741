import { useField } from "formik";
import React, { FC, ReactNode, useEffect, useState } from "react";

import { TextField } from "./fields";
import { parseBrowserLanguage } from "App";
import i18n from "i18next";

interface Props {
  [key: string]: unknown;
  component: typeof TextField; // TODO: create generic input type
  helperText?: string;
  required?: boolean;
  label: ReactNode;
  name: string;
  transformValue?: (val: string) => string;
}

export const Field: FC<Props> = ({
  component: C,
  required,
  label,
  name,
  helperText,
  transformValue,
  ...props
}) => {
  const [{ value, onChange }, { error, touched }, { setTouched }] = useField(name);
  const [currLang, setCurrLang] = useState(parseBrowserLanguage(i18n.language));
  useEffect(() => {
    if (parseBrowserLanguage(i18n.language) !== currLang) {
      setCurrLang(parseBrowserLanguage(i18n.language));
      /**
       * Whenever user changes the current langauge.
       * If the field has an error shown, then set the touched to false and true back again to ensure the error re-renders and appears with correct translation.
       */
      if (!!touched && !!error) {
        setTouched(false);
        setTimeout(() => {
          setTouched(true);
        }, 10);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <C
      {...props}
      error={!!touched && !!error && !!currLang}
      helperText={!!touched && !!error ? error : helperText}
      label={required ? <>{label}*</> : label}
      name={name}
      value={value}
      onBlur={() => setTouched(true)}
      onChange={(e) => {
        e.target.value = transformValue ? transformValue(e.target.value) : e.target.value;
        onChange(e);
      }}
    />
  );
};
