import cn from "classnames";
import { List } from "antd";
import { Select as AntdSelect } from "antd";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import type { SelectProps } from "antd/es/select";

import { FullScreenModal } from "components/FullScreenModal";
import { RenderDesktop, RenderMobile } from "components/RenderMatchMedia";

import styles from "./select.module.css";
import { Icon } from "components/Icon";

interface Props extends Omit<SelectProps<never>, "value"> {
  value?: string | number;
}

export const Select: FC<Props> = ({ options, onSelect, value, ...props }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <RenderMobile>
        <AntdSelect
          className={styles.select}
          open={false}
          onClick={() => setOpen(true)}
          {...props}
          options={options}
        />
        <FullScreenModal isOpen={open} title={t("FILTER")} onRequestClose={() => setOpen(false)}>
          <List
            dataSource={options}
            renderItem={(item) => (
              <List.Item
                className={cn(styles.mobileOption, {
                  [styles.activeMobileOption]: value === item.value,
                })}
                onClick={() => {
                  onSelect && onSelect(item.value as never, item);
                  setOpen(false);
                }}
              >
                <List.Item.Meta title={item.label} />
                {value === item.value ? <Icon name="check" size={22} /> : null}
              </List.Item>
            )}
            size="large"
          />
        </FullScreenModal>
      </RenderMobile>
      <RenderDesktop>
        <AntdSelect className={styles.select} options={options} onSelect={onSelect} {...props} />
      </RenderDesktop>
    </>
  );
};
