import cn from "classnames";
import React, { FC } from "react";
import { Popover as AntdPopover } from "antd";
import type { PopoverProps } from "antd/es/popover";

import "./popover.css";

interface Props extends PopoverProps {
  noArrow?: boolean;
  noSpace?: boolean;
  fixed?: boolean;
  size?: "large";
}
export const Popover: FC<Props> = ({ size, fixed, noSpace, noArrow, ...props }) => {
  return (
    <AntdPopover
      {...props}
      arrowContent={null}
      overlayClassName={cn({
        "pop-large": size === "large",
        "pop-no-arrow": noArrow,
        "pop-no-space": noSpace,
        "pop-pos-fixed": fixed,
      })}
    />
  );
};
