import { Divider, Tooltip } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Account } from "api";
import { Tag } from "components/Tag";
import { Card } from "components/Card";
import { Button } from "components/Button";
import { formatPrice } from "helpers/formatPrice";
import { Text, Title } from "components/Typography";
import suspended from "assets/images/suspended.svg";
import expired from "assets/images/expired.svg";

import { SharedTag } from "./SharedTag";
import { components } from "../../../../../api-schema";
import { useConfig } from "../../../../../state";
import { isAcctExpired } from "../AccountsList";
import "../account-list.css";
import { formatAccount } from "helpers/formatAccount";

interface Props {
  account: Account; // TODO: use api typings
  onReload?: (account: Account) => void;
}

export const AccountCard: FC<Props> = ({ account, onReload }) => {
  const { t } = useTranslation();

  const config: {
    amsSite?: components["schemas"]["AmsSite"];
  } = useConfig();

  const shared = account.sharedCustomerList || [];

  const autoload =
    account.autoloadDetails && account.autoloadDetails.subscriptionStatus
      ? account.autoloadDetails
      : undefined;

  const hasMealPlan = account.accountType === "MEAL_PLAN_ACCOUNT";
  const hasOneRule = account.ruleList && account.ruleList.length === 1;
  const mealPlan =
    hasMealPlan && account.ruleList && account.ruleList.length > 0
      ? account.ruleList[0].amount
      : undefined;

  const mealFrequency =
    hasMealPlan && account.ruleList && account.ruleList.length > 0
      ? account.ruleList[0].ruleName.toLowerCase().replace("per", "").trim()
      : undefined;

  // if account has groupId then try to find it in the accountBalances array
  // if present -- display the balance name, otherwise default label
  const balanceLabel = account.groupId
    ? config.amsSite?.siteTheme?.accountBalances?.find((acc) => acc.groupId === account.groupId)
        ?.balanceName || t("BALANCE")
    : t("BALANCE");

  const pointsLabel = config.amsSite?.siteTheme?.labels?.["label.points.title.text"];

  const renderSuspendTag = (type: "suspend" | "expire") => {
    return (
      <div className="d-flex flex-column">
        <Divider className="m-0" />
        <div className="px-4 py-1 text-center">
          <Tag
            className="w-100 d-inline-block text-truncate card-expired-acct"
            color="secondary"
            hasBorder={false}
          >
            <div className="d-flex justify-content-center">
              <img alt="" src={type === "suspend" ? suspended : expired} />
              <div className="ml-2">
                {type === "suspend" ? t("ACCOUNT_SUSPENDED") : t("ACCOUNT_EXPIRED")}
              </div>
            </div>
          </Tag>
        </div>
      </div>
    );
  };

  return (
    <Card elevated key={account.accountId} padding="none">
      <div className="p-4 flex-grow-1">
        <div className="d-flex justify-content-between align-items-start mb-3">
          <div>
            <Text className="d-flex flex-column mb-1" color="disabled">
              {/* TODO: remove fallback once api specs will be fixed */}
              {t("ID: {id}", {
                id: formatAccount(account?.accountDisplayId ?? (account?.accountId || "")),
              })}
            </Text>
            <Text size="medium">
              {account.accountName || (account?.accountDisplayId ?? (account?.accountId || ""))}
            </Text>
          </div>
          <SharedTag shared={shared} />
        </div>

        <div className="d-flex flex-column">
          <Text color="disabled" size="medium">
            {balanceLabel}
          </Text>
          <Title className="mt-2" level={3}>
            {account.accountType !== "MEAL_PLAN_ACCOUNT" && account.accountType !== "POINTS_ACCOUNT"
              ? formatPrice(account.balance)
              : account.accountType === "MEAL_PLAN_ACCOUNT"
              ? t("MEAL_PLURAL", {
                  balance: account.balance,
                })
              : !!pointsLabel
              ? `${account.balance} ${pointsLabel}`
              : t("POINTS_PLURAL", {
                  balance: account.balance,
                })}
          </Title>
        </div>

        {autoload && (
          <Tooltip
            title={`${t("AUTOLOAD")} ${formatPrice(autoload.loadAmount)} ${t(
              "WHEN_BALANCE_IS"
            )} ${formatPrice(autoload.thresholdAmount)}`}
          >
            <div className="text-center">
              <Tag
                className="w-100 d-inline-block text-truncate"
                color="secondary"
                hasBorder={false}
              >
                {`${t("AUTOLOAD")} `}
                <strong>{formatPrice(autoload.loadAmount)}</strong>
                {` ${t("WHEN_BALANCE_IS")} `}
                <strong>{formatPrice(autoload.thresholdAmount)}</strong>
              </Tag>
            </div>
          </Tooltip>
        )}
      </div>

      {hasMealPlan && hasOneRule && (
        <div className="text-center p-4">
          <Tag className="w-100" color="warning" hasBorder={false}>
            {t("MEAL_FREQUENCY_PLURAL", {
              frequency: mealFrequency,
              plan: mealPlan || 0,
            })}
          </Tag>
        </div>
      )}

      {isAcctExpired(account)
        ? renderSuspendTag("expire")
        : account.accountStatus === "SUSPENDED"
        ? renderSuspendTag("suspend")
        : !hasMealPlan &&
          account.loadAccountAllowed && (
            <div className="d-flex flex-column">
              <Divider className="m-0" />
              <div className="p-2 text-center">
                <Button type="link" onClick={() => onReload && onReload(account)}>
                  {t("LOAD_ACCOUNT")}
                </Button>
              </div>
            </div>
          )}
    </Card>
  );
};
