import React, { FC } from "react";

import { useIsMobile } from "helpers/isMobile";

export const RenderMobile: FC = ({ children }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <>{children}</>;
  }

  return null;
};
