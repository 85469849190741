// eslint-disable-next-line no-restricted-imports
import { Provider as Redux } from "react-redux";
import React, { FC, useEffect, ComponentType, useRef } from "react";
import { PersistGate } from "redux-persist/integration/react";

import { AppInitializer } from "./AppInitializer";
import { createDevAppStore, createAppStore } from "../helpers";
import { AppState } from "state";

// Count instances of app state provider
let numOfInstances = 0;

interface Props {
  /**
   * Content displayed until App is ready
   */
  initializer?: ComponentType<never>;
  /**
   * Minimum time to display Initial page
   */
  minDelay?: number;
  /**
   * initial data - use when u want to overwrite initialization (e.g. testing)
   */
  initialState?: Partial<AppState>;
  /**
   * skip init render
   */
  ignoreInit?: boolean;
}

/**
 * Global app wrapper to provide shared state
 */
export const Provider: FC<Props> = ({ children, ignoreInit, initializer, initialState }) => {
  const constructed = useRef(
    process.env.NODE_ENV === "development"
      ? createDevAppStore(initialState)
      : createAppStore(initialState)
  );

  const { store, persistor } = constructed.current;

  useEffect(() => {
    numOfInstances++;
    if (numOfInstances > 1) {
      throw new Error("You can have only one App Provider");
    }
    return () => {
      numOfInstances--;
    };
  }, []);

  // TODO: read more about persistor "loading" state
  return (
    <Redux store={store}>
      {persistor ? (
        <PersistGate persistor={persistor}>
          <AppInitializer ignoreInit={ignoreInit} initializer={initializer}>
            {children}
          </AppInitializer>
        </PersistGate>
      ) : (
        <AppInitializer initializer={initializer}>{children}</AppInitializer>
      )}
    </Redux>
  );
};
