export const isFloat = (n: number | string): boolean => {
  return n.toString().split(".").length > 1;
};

// custom toFixed implementation without rounding
export const toFixed = (num: number | string, fixed: number): number | string => {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  return num.toString()?.match(re)?.[0] || num;
};

export const countDecimals = (number: number): number => {
  if (Math.floor(number) === number) return 0;
  return number.toString().split(".")[1].length || 0;
};

export const withMaxDecimals = (number: number | string, maxDecimals = 2): number | string => {
  if (isNaN(Number(number)) || !isFloat(number)) return number;
  return countDecimals(number as number) < maxDecimals ? number : toFixed(number, maxDecimals);
};
