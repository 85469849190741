import React, { FC, SyntheticEvent } from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";

import { getHistory, getLastPath } from "helpers/history";

/**
 * go back to previous page from history
 */
type BackLinkProps = Omit<LinkProps, "to"> & {
  back: true;
};

type Props = BackLinkProps | LinkProps;

/**
 * Custom link component
 * - for handling spectial routes with /:theme prefix
 * - for handling "goBack" links
 * - for handling external links = when TO begins with http:// or https://
 *
 * you can pass BACK property, then TO its not required, and user
 * action will be routed one step backward in browser history
 */
export const Link: FC<Props> = (props) => {
  const back = "back" in props && props.back; // back link props
  const to = ("to" in props && props.to) || "#"; // regular link props
  const external = typeof to === "string" && /^http(s)*:\/\//.test(to);

  const customHandler =
    external || back || (typeof to === "string" && to.startsWith("/:theme"))
      ? (e: SyntheticEvent) => {
          e.preventDefault();
          if (external && typeof to === "string") {
            if (props.target === "_blank") {
              window.open(to, props.target);
            } else {
              window.location.replace(to);
            }
          } else if (back) {
            getHistory().goBack();
          } else if (typeof to === "string") {
            getHistory().pushWithTheme(to.replace("/:theme", ""));
          }
        }
      : undefined;

  const passingProps = { ...props } as Partial<BackLinkProps>; // little hack how to prevent ts-ignore
  delete passingProps.back;

  // TODO: Replace /:theme with current to make link more accurate
  return (
    <RouterLink
      to={"back" in props ? getLastPath() : to}
      onClick={customHandler}
      {...passingProps}
    />
  );
};
