/**
 * Return app currency
 *
 * TODO: connect with store once it will be required
 */
export const getCurrency = (): string => "CAD";

/**
 * Return app currency
 *
 * TODO: connect with store once it will be required
 */
export const getCurrencySymbol = (): string => "$";
