import { Api } from "api";
import ReactToPrint from "react-to-print";
import { Button } from "components/Button";
import { useTranslation } from "react-i18next";
import React, { createRef, FC, useEffect, useMemo, useRef } from "react";

import { Modal } from "components/Modal";
import { useConfig, useCustomer, useLazyApiCall } from "state";
import { apiServices, userFullName } from "helpers";

import { Receipt } from "./Receipt";

interface Props {
  isOpen: boolean;
  transactionId?: string;
  onRequestclose?: () => void;
}

export const ReceiptModal: FC<Props> = ({ isOpen, transactionId, onRequestclose }) => {
  const { dispatch, triggered, data, error, isLoading } = useLazyApiCall<Api["TransWeb"]>(
    apiServices.transaction(transactionId || "0")
  );
  const dispatchRef = useRef(dispatch);
  const customer = useCustomer();
  const { t } = useTranslation();
  const config = useConfig();

  const receiptRef = createRef<HTMLPreElement | HTMLDivElement>();

  useEffect(() => {
    dispatchRef.current = dispatch;
  }, [dispatch]);

  useEffect(() => {
    if (transactionId && dispatchRef.current) {
      dispatchRef.current(apiServices.transaction(transactionId || "0"), 1);
    }
  }, [transactionId]);

  const actins = useMemo(
    () => [
      <ReactToPrint
        content={() => receiptRef.current}
        key="print"
        trigger={() => (
          <Button block disabled={!!error || isLoading} key="print" type="secondary">
            {t("PRINT_RECEIPT")}
          </Button>
        )}
      />,
    ],
    [error, isLoading, receiptRef, t]
  );

  return (
    <Modal
      maxHeight
      actions={actins}
      isOpen={isOpen}
      padding="none"
      title={t("TRANSACTION_BY_NAME", { name: userFullName(customer) })}
      onRequestClose={onRequestclose}
    >
      <Receipt
        ref={receiptRef}
        error={error}
        isLoading={!triggered || isLoading}
        /**
         * If the connector type is "VE" then display VE receipt from POS else call V7 receipt
         */
        receipt={config.connectorType === "VE" ? data?.veReceipt : data?.receipt || ""}
      />
    </Modal>
  );
};
