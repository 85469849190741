import { Empty } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import "./empty.css";

interface Props {
  description?: string;
}

export const NoData: FC<Props> = ({ description }) => {
  const { t } = useTranslation();
  return (
    <div className="custom-emtpy p-1 w-100 text-center my-4">
      <Empty
        aria-label="empty placeholder"
        description={description || t("NO_DATA")}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    </div>
  );
};
