import { Spin } from "antd";
import { SpinProps, SpinState } from "antd/lib/spin";
import React from "react";
import "./loader.css";
import cn from "classnames";

interface Props extends SpinProps, SpinState {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Loader = (props: Props) => {
  return (
    <>
      <Spin className={"loader"} size={props.size} spinning={props.spinning} />
      <div className={cn(props.spinning && "containerBlur", "loaderContainer")}>
        {props.children}
      </div>
    </>
  );
};
