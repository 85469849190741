import qs, { ParsedQs } from "qs";
import { useLocation } from "react-router-dom";

/**
 * Stringify Query parameters
 * - centralized serializer
 * - adding ?
 * @param {Json} data
 */
export function stringify(data?: undefined | Record<string, unknown>): string {
  if (!data) {
    return "";
  }
  const val = qs.stringify(data);
  return val ? `${val}` : "";
}

/**
 * Parse Query parameters
 * - centralized serializer
 * @param {Json} data
 */
export function parse(string: string): ParsedQs {
  if (!string) {
    return {};
  }

  const val = qs.parse(string.replace(/^\?/, ""));
  return val || {};
}

export function useQueryParams(): ParsedQs {
  const { search } = useLocation();
  try {
    return qs.parse(search.replace(/^[?]{1}/, "")) || {};
  } catch {
    return {};
  }
}
