import React, { FC } from "react";
import { Dialog } from "@reach/dialog";

import "@reach/dialog/styles.css";

import { Icon } from "components/Icon";

import "./dialog.css";
import { Button } from "components/Button";

interface Props {
  onRequestClose?: () => void;
  isOpen: boolean;
  title?: string;
}

export const FullScreenModal: FC<Props> = ({ children, isOpen, onRequestClose, title }) => {
  return (
    <Dialog aria-label={title || "modal-window"} isOpen={isOpen}>
      <div className="header">
        <span>{title}</span>
        <Button type="text" onClick={onRequestClose}>
          <Icon name="close" size={24} />
        </Button>
      </div>
      <div className="content">{children}</div>
    </Dialog>
  );
};
