import React, { FC } from "react";
import { Row as AntdRow } from "antd";
import type { RowProps } from "antd/es/row";

export const Row: FC<RowProps> = ({ children, ...props }) => {
  if (process.env.NODE_ENV === "test") {
    return <div>{children}</div>;
  }

  return <AntdRow {...props}>{children}</AntdRow>;
};
