import * as Yup from "yup";
import { message, Select } from "antd";
import { useTranslation } from "react-i18next";
import React, { FC, useMemo, useCallback, useEffect } from "react";

import { Link } from "components/Link";
import { Button } from "components/Button";
import { Auth as AuthLayout } from "layouts";
import { passwordValidator } from "helpers/yup";
import { apiServices, getHistory } from "helpers";
import { getConfig, useConfig, useLazyApiCall } from "state";
import { Text, Title } from "components/Typography";
import { PasswordField } from "components/Form/fields/PasswordField";
import { Form, TextField, Field, CheckboxField } from "components/Form";
import { RenderDesktop, RenderMobile } from "../../components/RenderMatchMedia";
import i18n from "i18next";
import styles from "./common.module.css";
import icon from "../../assets/images/language.svg";
import { parseBrowserLanguage } from "App";

export const SignUp: FC = () => {
  const { dispatch, error, ok, isLoading } = useLazyApiCall(apiServices.signUp());
  const { t } = useTranslation();
  const config = useConfig();
  const isVE = config.connectorType === "VE";
  const siteTheme = config.amsSite?.siteTheme;
  const accountId = siteTheme?.labels?.["label.auth.account.id"];
  const signupLabel = siteTheme?.labels?.["label.auth.signup"];
  const signupButtonText = siteTheme?.labels?.["button.auth.signup"];
  const loginButtonText = siteTheme?.labels?.["button.auth.login"];

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          confirmPassword: Yup.string()
            .required(t("FIELD_REQUIRED"))
            .test(passwordValidator as never)
            .nullable()
            .test("same", t("PASSWORD_DOESNT_MATCH"), function samePasswords(value): boolean {
              return !value || value === this.parent.password;
            })
            .default(null),
          customerCode: Yup.string().required(t("FIELD_REQUIRED")).nullable().default(null),
          email: Yup.string()
            .email(t("ENTER_VALID_EMAIL"))
            .required(t("FIELD_REQUIRED"))
            .nullable()
            .default(null),
          password: Yup.string()
            .required(t("FIELD_REQUIRED"))
            .test(passwordValidator as never)
            .nullable()
            .default(null),
          rememberMe: Yup.boolean().default(false),
          tcAgreement: Yup.boolean()
            .test("mustAgree", t("READ_TERMS_CONDITIONS"), Boolean)
            .default(false),
        })
        .defined(),
    [t]
  );

  const initialValues = useMemo(() => schema.cast({}), [schema]);

  type FormData = Yup.InferType<typeof schema>;

  const handleSubmit = useCallback(
    (data: FormData) => {
      const { customerCode, password, email } = data;
      // @ts-ignore TODO: inspect
      dispatch(
        {
          body: {
            customerCode: customerCode || "",
            email: email || "",
            password: password || "",
            userType: "EMPLOYEE",
          },
        },
        1
      );
    },
    [dispatch]
  );

  useEffect(() => {
    //V7 displays the same error for all errors returned by BE, VE displays the error returned by BE
    if (error) {
      if (isVE) {
        if (((error as unknown) as string).includes("AMS access code not unique")) {
          message.error(t("ISSUE_WITH_ACCOUNT_ID_MESSAGE"));
        } else if (((error as unknown) as string).includes("Customer email not match")) {
          message.error(t("CUSTOMER_EMAIL_NOT_MATCH"));
        } else {
          message.error(error);
        }
      } else {
        message.error(t("SIGN_UP_FAILED"));
      }
    }
  }, [error, isVE, t]);

  useEffect(() => {
    if (ok) {
      message.success(t("ACCOUNT_CREATED_MESSAGE"));
      getHistory().pushWithTheme("/login");
    }
  }, [ok, t]);

  const termsLink = getConfig().amsSite?.term;

  return (
    <>
      <RenderDesktop>
        <Select
          className={styles.languageSelectorDropdown}
          defaultValue={parseBrowserLanguage(i18n.language)}
          size="large"
          value={parseBrowserLanguage(i18n.language)}
          onSelect={(val) => i18n.changeLanguage(val)}
        >
          <Select.Option value={"en"}>
            <div className={styles.languageOption}>
              <span>{i18n.t("ENGLISH")}</span>
              <img alt="" className={styles.langIcon} src={icon} />
            </div>
          </Select.Option>
          <Select.Option value={"fr"}>
            <div className={styles.languageOption}>
              <span>{i18n.t("FRENCH")}</span>
              <img alt="" className={styles.langIcon} src={icon} />
            </div>
          </Select.Option>
        </Select>
      </RenderDesktop>

      <RenderMobile>
        <div className={styles.languageSelectContainer}>
          <div className={styles.currentLanguage}>
            <img alt="" className={styles.langIcon} src={icon} />
            <span>{i18n.language}</span>
          </div>

          <Button
            className={"changeLanguage"}
            type={"link"}
            onClick={() =>
              i18n.changeLanguage(parseBrowserLanguage(i18n.language) === "fr" ? "en" : "fr")
            }
          >
            {t("CHANGE_LANGUAGE_TO", {
              language: t(parseBrowserLanguage(i18n.language) === "fr" ? "ENGLISH" : "FRENCH"),
            })}
          </Button>
        </div>
      </RenderMobile>

      <AuthLayout>
        <Form
          id="loginForm"
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <div className="d-block flex-col">
            <Title className="mb-5" level={1}>
              {signupLabel ? signupLabel : t("SIGN_UP")}
            </Title>
            <Field
              component={TextField}
              label={accountId ? accountId : t("ACCOUNT_ID")}
              name="customerCode"
              placeholder={t("ACCOUNT_ID")}
            />

            <Field
              component={TextField}
              label={t("EMAIL")}
              name="email"
              placeholder={t("ENTER_YOUR_EMAIL")}
            />

            <Field
              autoComplete="off"
              component={PasswordField}
              label={t("PASSWORD")}
              name="password"
              placeholder={t("PASSWORD")}
              type="password"
            />

            <Field
              autoComplete="off"
              component={PasswordField}
              label={t("CONFIRM_PASSWORD")}
              name="confirmPassword"
              placeholder={t("REPEAT_PASSWORD")}
              type="password"
            />

            <div className="d-flex flex-row mb-4 align-items-center">
              <Field
                className="mb-0"
                component={CheckboxField}
                label={
                  <>
                    <Text>{t("I_AGREE_WITH")}</Text>{" "}
                    {termsLink ? (
                      <Link target="_blank" to={termsLink}>
                        {t("TERMS_AND_CONDITIONS")}
                      </Link>
                    ) : (
                      t("TERMS_AND_CONDITIONS")
                    )}
                  </>
                }
                name="tcAgreement"
              />
            </div>

            <Button block disabled={isLoading} htmlType="submit" type="primary">
              {signupButtonText ? signupButtonText : t("SIGN_UP")}
            </Button>

            <div className="text-center mt-4">
              {t("ALREADY_HAVE_ACCOUNT")}
              <Link to="/:theme/login">{loginButtonText ? loginButtonText : t("LOG_IN")}</Link>
            </div>
          </div>
        </Form>
      </AuthLayout>
    </>
  );
};
