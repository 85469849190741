/* eslint-disable no-inline-styles/no-inline-styles,@typescript-eslint/no-unused-vars */
import { useField } from "formik";
import React, { FC, useState } from "react";

import { Col, Row } from "antd";
import { Card } from "../../../../components/Card";
import Paragraph from "antd/es/typography/Paragraph";
import { Text } from "../../../../components/Typography";
import { formatPrice } from "../../../../helpers";
import { PointBucket } from "../ManualReload";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../../../state";

interface Props {
  pointBuckets: PointBucket[];
}

export const PointBuckets: FC<Props> = ({ pointBuckets }) => {
  const [_, __, { setValue }] = useField("pointsBucketId");
  const config = useConfig();

  const [selectedPointsBucketId, setSelectedPointsBucketId] = useState("");
  const { t } = useTranslation();

  const pointsLabel = config.amsSite?.siteTheme?.labels?.["label.points.title.text"] ?? t("POINTS");

  return (
    <Row>
      {pointBuckets.slice(0, 8).map((bucket) => (
        <Col md={6} xs={24}>
          <Card
            className={"mx-2 my-2"}
            style={{
              alignItems: "center",
              backgroundColor: bucket.bucketId === selectedPointsBucketId ? "#f9f9f9" : "unset",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              height: 150,
              outline:
                bucket.bucketId === selectedPointsBucketId
                  ? "2px solid #597ef7"
                  : "0.5px solid lightgray",
              textAlign: "center",
            }}
            onClick={() => {
              setSelectedPointsBucketId(bucket.bucketId || "");
              setValue(bucket.bucketId);
            }}
          >
            <div style={{ marginTop: -12 }}>
              <Paragraph strong ellipsis={{ rows: 2 }}>
                <div>{bucket.bucketName}</div>
              </Paragraph>
              <Text strong className={"mx-a"} size="large">
                <div>{formatPrice(bucket.price)}</div>
              </Text>
              <Paragraph strong ellipsis={{ rows: 3 }}>
                <div>{`${bucket.pointsAward} ${pointsLabel}`}</div>
              </Paragraph>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
