import { Col, Divider, message, Row } from "antd";
import { useTranslation } from "react-i18next";
import React, { FC, Fragment, useCallback, useEffect, useState } from "react";

import { Api } from "api";
import { Tag } from "components/Tag";
import { apiServices } from "helpers";
import { Card } from "components/Card";
import { Button } from "components/Button";
import { Text } from "components/Typography";
import { CreditCardIcon } from "components/Icon";
import { PaymentModal } from "containers/Payment";
import { useConfig, useCustomer, useLazyApiCall } from "state";
import { maskCardNumber } from "helpers/maskCardNumber";
import type { PaymentResponse } from "containers/Payment";

import { RemovePaymentModal } from "./containers/RemovePaymentModal";
import { refreshCustomer } from "state/effects/refreshCustomer";
import { Loader } from "../../../components/Loader";

export const Payment: FC = () => {
  const [requestDelete, setToDelete] = useState<{ method?: Api["SavedPayment"]; open: boolean }>({
    open: false,
  });
  const { ok, error, dispatch } = useLazyApiCall(apiServices.savePayment(), refreshCustomer);
  const [newCard, setNewCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const customer = useCustomer()!;
  const config = useConfig();
  const isVE = config.connectorType === "VE";

  const methods =
    customer && customer.savedPayments && customer.savedPayments.length > 0
      ? customer.savedPayments
      : [];

  const handleReplace = useCallback(
    (data: PaymentResponse) => {
      setLoading(true);

      const body =
        data.type === "PAY_CONEX"
          ? {
              creditCardExpiry: data.data.masked.expy,
              creditCardLast4: Number(data.data.masked.number.substr(-4)),
              creditCardToken: data.data.eToken,
              type: data.type,
            }
          : {
              attributes: data.data.attributes,
              nameOnCard: data.data.nameOnCard,
              paymentKey: data.data.paymentKey,
              sessionKey: data.data.sessionKey,
              type: data.type,
            };

      dispatch({ body }, 1);
    },
    [dispatch]
  );

  useEffect(() => {
    if (error) {
      setNewCard(false);
      message.error(t("ERROR_SAVING_PAYMENT_METHOD"));
    }
  }, [error, t]);

  useEffect(() => {
    if (ok) {
      setNewCard(false);
      message.success(t("NEW_PAYMENT_METHOD_SAVED"));
    }
  }, [ok, t]);

  useEffect(() => {
    setLoading(false);
  }, [customer]);

  return (
    <Loader size={"large"} spinning={loading}>
      <Card className="py-0 py-md-5" padding="none">
        {methods.map((it: Api["SavedPayment"], k) => (
          <Fragment key={k}>
            <Row>
              <Col
                className="d-flex align-items-md-center justify-content-between px-4 py-3 flex-xs-column"
                md={18}
                xs={24}
              >
                <div className="px-md-3 d-flex d-xs-inline align-items-center mb-0 justify-content-between flex-1">
                  <div>
                    <CreditCardIcon className="mr-2" type={it.creditCardType} />
                    <Text size="medium">{maskCardNumber(it.creditCardLast4)}</Text>
                  </div>
                  {!isVE && it.saveCard && (
                    <Tag className="ml-4" color="secondary" size="small">
                      {t("DEFAULT_PAYMENT")}
                    </Tag>
                  )}
                </div>
              </Col>
            </Row>

            <Divider className="m-0" />

            <div className="px-4 py-3">
              <Button
                noPadding
                className="ml-0 ml-md-3"
                type="link"
                onClick={() => {
                  setToDelete({ method: it, open: true });
                  setLoading(true);
                }}
              >
                {t("REMOVE")}
              </Button>
            </div>

            <Divider className="m-0" />
          </Fragment>
        ))}

        <div className="px-4 py-3">
          <Button noPadding className="ml-0 ml-md-3" type="link" onClick={() => setNewCard(true)}>
            {methods && methods.length > 0 && !isVE ? t("REPLACE") : t("ADD_NEW_PAYMENT_METHOD")}
          </Button>
        </div>
      </Card>

      <RemovePaymentModal
        isOpen={requestDelete.open}
        target={requestDelete.method}
        onRequestClose={(bool) => {
          bool && setLoading(false);
          setToDelete((s) => ({ ...s, open: false }));
        }}
      />

      <PaymentModal
        isOpen={newCard}
        onRequestClose={() => setNewCard(false)}
        onSuccess={handleReplace}
      />
    </Loader>
  );
};
