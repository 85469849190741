/* eslint-disable no-inline-styles/no-inline-styles */
// core
import React, { FC } from "react";
// components
import { Button } from "../../components/Button";
import { ExclamationCircleFilled } from "@ant-design/icons";
// i18n
import { useTranslation } from "react-i18next";
// nav
import { useHistory } from "react-router-dom";

export const DomainNotFound: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center px-4" style={{ marginTop: "15vh" }}>
      <ExclamationCircleFilled style={{ color: "#D0D4DD", fontSize: 60, marginBottom: "15px" }} />
      <span
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px", textAlign: "center" }}
      >
        {t("CANT_FIND_PAGE")}
      </span>
      <span style={{ color: "#5a6872", fontSize: "14px", marginBottom: "40px" }}>
        {t("CONTACT_US_MESSAGE")}:{" "}
        <a href="mailto: support@volantesystems.com">support@volantesystems.com</a>
      </span>
      <Button onClick={onBack}>{t("GO_BACK_TO_PREV_PAGE")}</Button>
    </div>
  );
};
